import BaseAPI from "utils/BaseAPI";

export const actionsPrint = async ({ id, printData }) => {
  console.log("id", id);
  try {
    const response = await BaseAPI.post(`/lottery/one/${id}`, printData);
    return { status: true, data: response };
  } catch (err) {
    return { status: false, err };
  }
};

export const updateStatusLottery = async ({ id, data }) => {
  console.log("id", id);
  try {
    const response = await BaseAPI.put(`/lottery/one/${id}`, data);
    return { status: true, data: response };
  } catch (err) {
    return { status: false, err };
  }
};
