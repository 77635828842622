import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Input, Modal, notification, Table,Checkbox, } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatCurrency } from 'utils/format-currency';
import BaseAPI from '../../../utils/BaseAPI';
import moment from 'moment'

import './style.scss';


const options = [
  { label: 'Nạp Tiền', value: 'deposit' },
  { label: 'Rút tiền', value: 'withdraw' },
  { label: 'Danh sách tài khoản', value: 'userList' },
  { label: 'Khoá tài khoản', value: 'banUser' },
  { label: 'Thống kê bán hàng', value: 'saleBoard' },
  { label: 'Lịch sử giao dịch', value: 'transactionList' },
  { label: 'Lịch sử rút tiền', value: 'withdrawList' },
  { label: 'Lịch sử vé bán', value: 'saleList' },
  { label: 'Lịch sử vé bán (Web)', value: 'saleListWeb' },
  { label: 'Vé truyền thống', value: 'ticket' },
  { label: 'App bán hàng', value: 'app' },
]


const UserListScreen = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [id, setId] = useState();
  const [itemUser, setItemUser] = useState();
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await BaseAPI.get('/user/all?active=true&limit=100000');

      if (response.status) {
        setList(response.data.docs);
      }
    } catch (e) {
      //
    }
    setIsLoading(false);
  };

  const computedList = useMemo(() => {
    if (!keyword) {
      return list;
    }

    return list.filter(
      (it) => it.phoneNumber.indexOf(keyword) >= 0 || it.name.indexOf(keyword) >= 0,
    );
  }, [keyword, list]);

  const onChangeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  };

  const onUpgradeUser = (user, isUp) => async () => {
    const update = { ...user, role: isUp ? 'vip' : 'user' };
    const response = await BaseAPI.put(`/user/${user._id}`, update);

    if (response) {
      const findUserIndex = list.findIndex((it) => it._id === user._id);
      const newList = cloneDeep(list);
      newList[findUserIndex].role = isUp ? 'vip' : 'user';
      setList(newList);
    }

    Modal.success({
      title: 'Thành công',
      content: 'Đã cập nhật thông tin tài khoản',
    });
  };

  const onLockUser = useCallback(
    (id, user) => () => {
      Modal.confirm({
        title: 'Xác nhận',
        content: 'Bạn có chắc muốn khoá tài khoản này?',
        onOk: async () => {
          const response = await BaseAPI.put(`/user/${id}`, {
            ...user,
            active: false,
          });

          if (response) {
            setList(list.filter((it) => it._id !== id));
          }
        },
      });
    },
    [list],
  );

  const onDeleteUser = useCallback(
    (id, user) => () => {
      Modal.confirm({
        title: 'Xác nhận',
        content: 'Bạn có chắc muốn xoá tài khoản này?',
        onOk: async () => {
          const response = await BaseAPI.delete(`/user/${id}`, {
            ...user,
            active: false,
          });

          if (response) {
            setList(list.filter((it) => it._id !== id));
          }
        },
      });
    },
    [list],
  );

  const handleUpdateUser = (id, row) => () => {
    let roleName;
    setId(id);
    setItemUser(row);
    if (id) {
      switch (row.role) {
        case 'user':
          roleName = 'Khách hàng';
          break;
        case 'vip':
          roleName = 'VIP';
          break;
        case 'agency':
          roleName = 'Cửa hàng';
          break;
        case 'staff':
          roleName = 'Nhân viên';
          break;
        default:
          roleName = row.role;
          break;
      }
      form.setFieldsValue({
        name: row.name,
        phoneNumber: row.phoneNumber,
        balance: formatCurrency(row.balance),
        cardID: row.cardID,
        role: roleName,
        permission: row.permission
      });
    } else form.resetFields();
    setIsVisible(true);
  };

  const onFinish = async (formValues) => {
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('phoneNumber', formValues.phoneNumber);
    try {

      const updateData = {
        ...itemUser,
        name: formValues.name,
        phoneNumber: formValues.phoneNumber,
        permission: formValues.permission,
        cardID: formValues.cardID
      }

      if(formValues.password){
        updateData.password = formValues.password
        updateData.passwordConfirm = formValues.password
      }

      const response = await BaseAPI.put(`/user/${id}`,updateData);
      
      if (response.status && response.data !== '404 Not Found') {
        notification.success({
          title: 'Thành công',
          description: 'Cập nhật thành công',
          placement: 'bottomRight',
        });
        const indexUser = list.findIndex((list) => list.id === id);
        const dataRes = response.data.doc ? response.data.doc : null;
        setList([...list.slice(0, indexUser), dataRes, ...list.slice(indexUser + 1)]);
      } else
        notification.error({
          title: 'Lỗi',
          description: 'Cập nhật thất bại',
          placement: 'bottomRight',
        });
    } catch (e) {
      notification.error({
        title: 'Lỗi',
        description: 'Xảy ra lỗi mã lỗi -5001',
        placement: 'bottomRight',
      });
    }
    // fetchUsers({ page: 1 });
    setIsVisible(false);
  };

  const onFinishFailed = () => {
    notification.error({
      title: 'Lỗi',
      description: 'Vui lòng kiểm tra thông tin đã nhập!',
      placement: 'bottomRight',
    });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số dư',
      dataIndex: 'balance',
      key: 'balance',
      render: (cell) =>
        new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(cell),
    },
    {
      title: 'Ngày mở TK',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (cell) =>
        moment(cell).format("LL"),
    },
    {
      title: 'Phân quyền',
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        switch (role) {
          case 'user':
            return 'Khách hàng';
          case 'vip':
            return 'VIP';
          case 'agency':
            return 'Cửa hàng';
          case 'staff':
            return 'Nhân viên';
          default:
            return role;
        }
      },
    },
    {
      title: 'Mở khoá',
      dataIndex: 'id',
      align: 'right',
      render: (id, row) => (
        <>
          {row.role === 'user' && (
            <Button type='primary' size='small' onClick={onUpgradeUser(row, true)}>
              Nâng VIP
            </Button>
          )}

          {row.role === 'vip' && (
            <Button type='primary' size='small' onClick={onUpgradeUser(row, false)}>
              Hạ VIP
            </Button>
          )}
          {/* <Link to={{
            pathname: `/user/new/${id}`,
            state: row,
          }}
          > */}
          <Button
            type='primary'
            style={{ marginLeft: 12 }}
            size='small'
            onClick={handleUpdateUser(id, row)}>
            <EditOutlined /> Chỉnh sửa
          </Button>
          {/* </Link> */}

          <Button
            type='danger'
            style={{ marginLeft: 12 }}
            onClick={onLockUser(id, row)}
            size='small'>
            <LockOutlined />
            Khoá
          </Button>
          
          <Button
            type='danger'
            style={{ marginLeft: 12 }}
            onClick={onDeleteUser(id, row)}
            size='small'>
            <DeleteOutlined />
            Xoá TK
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className='keyword-row'>
        <h1>Danh sách tài khoản</h1>
        <div className='search'>
          <Input.Search placeholder='Nhập SĐT Hoặc tên' onChange={onChangeKeyword} />
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={[...computedList]}
        pagination={{ pageSize: 10 }}
        columns={columns}
        locale={{
          emptyText: <Empty description='Chưa có dữ liệu hoặc không tìm thấy' />,
        }}
      />
      <Modal
        title='Thông tin user'
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={() => form.submit()}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            labelCol={{ span: 6 }}
            name='name'
            label='Tên'
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên user',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 6 }}
            name='phoneNumber'
            label='Số điện thoại'
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số điện thoại',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 6 }}
            name='cardID'
            label='CCCD/CMND'
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập CCCD/CMND',
              },
            ]}>
            <Input />
          </Form.Item>
          
          <Form.Item
            labelCol={{ span: 6 }}
            name='password'
            label='Cấp lại mật khẩu'
            >
            <Input />
          </Form.Item>
          <Form.Item labelCol={{ span: 6 }} name='balance' label='Số dư'>
            <Input disabled={true} />
          </Form.Item>
          <Form.Item labelCol={{ span: 6 }} name='role' label='Quyền' style={{display: 'none'}}>
            <Input disabled={true} />
          </Form.Item>

          <Form.Item labelCol={{ span: 6 }} name="permission" label="Phân Quyền">
                <Checkbox.Group options={options} />
          </Form.Item>
        </Form>
      </Modal>
      ;s
    </div>
  );
};

export default UserListScreen;
