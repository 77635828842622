import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { Table, Tag, Space, Tabs, Button, Modal } from "antd";
import {
  PrinterOutlined,
  DeleteOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  getStatusLottery,
  getTypeLottery,
  getColorStatusLottery
} from "../../constant/status-vietlott";
import { formatCurrency } from "../../utils/format-currency";

import { actionsPrint, updateStatusLottery } from "./actions";
import {
  ORDER,
  CANCEL,
  COMPLETE,
  SHIPPING
} from "../../constant/status-vietlott";

import InfoLottery from "./components/InfoLottery";

import BaseAPI from "utils/BaseAPI";

const { TabPane } = Tabs;
const LIMIT = 10;

const StatusOrder = () => {
  const [visible, setVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState("all");
  const [currentData, setCurrentData] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const [tabs, setTabs] = useState([
    {
      name: "Tất cả",
      slug: "all"
    },
    {
      name: "Mới",
      slug: ORDER
    },
    {
      name: "Đang vận chuyển",
      slug: SHIPPING
    },
    {
      name: "Thành công",
      slug: COMPLETE
    },

    {
      name: "Huỷ",
      slug: CANCEL
    }
  ]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LIMIT,
    total: 1
  });
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [columns, setColumns] = useState([
    {
      title: "Loại",
      dataIndex: "type",
      key: "type",
      render: (type) => <p>{getTypeLottery(type)}</p>
    },

    {
      title: "Nhận Hàng",
      dataIndex: "delivery",
      key: "delivery",
      render: (status) => {
        const text = status ? "Giao hàng" : "Nhận tại shop";
        const color = status ? "#2db7f5" : "#87d068";
        return <Tag color={color}>{text.toUpperCase()}</Tag>;
      }
    },
    {
      title: "Thông tin vé",
      dataIndex: "price",
      key: "price",
      render: (money, row) => {
        let lottery = [];
        
        try{
          lottery = JSON.parse(row.data.lottery)
        }  catch(e){
          console.log(`🐳 -> StatusOrder -> e`, e)
        }

        if(row.data.isSupport){
          return <InfoLottery dataInfo={row}/>
        }
        
        return (
          <div>
            <p>{formatCurrency(money)}</p>
          </div>
        )
      }
    },
    {
      title: "Trạng Thái",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <>
          <Tag color={getColorStatusLottery(status)}>
            {getStatusLottery(status)}
          </Tag>
        </>
      )
    },
    {
      title: "Thao tác",
      key: "action",
      render: (data) => (
        <Space size='middle'>
          <Button
            onClick={() => handlePrintLottery(data)}
            type='primary'
            shape='round'
            icon={<PrinterOutlined />}
            size="small"
          >
            {get(data, "data.isPrint", false) ? "In Lại vé này" : "In vé"}
          </Button>

          <Button
            onClick={() => showModal(data)}
            type='primary'
            shape='round'
            icon={<InfoCircleOutlined />}
            size="small"
          >
            Xem thông tin
          </Button>

          {data.data.isSupport && <Button
            onClick={() => {
              if(confirm('Bạn có chắc muốn huỷ vé này?')){
                handleToggleStatusLottery(data)
              }
            }}
            danger={true}
            shape="round"
            icon={<DeleteOutlined />}
            size="small">
            Huỷ vé
          </Button>}
          
        </Space>
      )
    }
  ]);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    fetchData({ status: null, page: 1 });
  }, []);

  const callback = (key) => {
    const status = key === "all" ? null : key;
    fetchData({ status });
  };

  const showModal = (item) => {
    setCurrentData(item);
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const handleUpdateStatusLottery = async ({ data, id }) => {
    const response = await updateStatusLottery({ data, id });
    if (!response.status) return;
    fetchData({ status: null, page: 1 });
  };

  const handleToggleStatusLottery = (item) => {
    const id = get(item, "data._id", "");
    const data = {
      status: CANCEL
    };
    handleUpdateStatusLottery({ id, data });
  };

  const fetchData = async ({ status, page }) => {
    try {
      setLoadingTable(true);
      let params = {};
      const objTemp = {
        limit: LIMIT,
        page,
        isFromWeb: true,
        status,
        sort: "-createdAt"
      };

      for (const key in objTemp) {
        if (!!objTemp[key]) params[key] = objTemp[key];
      }
      console.log("this is param", params);
      const response = await BaseAPI.get(`/lottery/all`, { params });
      setLoadingTable(false);
      if (!response.status) return;
      const { data, total } = response;
      console.log("Day la res", response);
      const arrTemp = get(data, "docs", []).map((item, index) => ({
        key: index,
        type: get(item, "lotteryType", "type default"),
        price: get(item, "totalPrice", "price default"),
        delivery: get(item, "isReceive", true),
        status: get(item, "status", "status default"),
        data: item
      }));
      setDataTable(arrTemp);
      setPagination({
        ...pagination,
        total
      });
    } catch (e) {
      //
    }
  };

  const handlePrintLottery = async (data) => {
    const { customer = {} } = data.data;
    const id = get(data, "data._id", "");
    const printData = {
      ...data.lottery,
      phoneNumber: get(customer, "phoneNumber", ""),
      isUseBalance: get(data, "data.isUseBalance", false),
      infoUser: {
        phoneNumber: get(customer, "phoneNumber", ""),
        name: get(customer, "name", ""),
        period: get(data, "data.ky", "")
      }
    };
    const response = await actionsPrint({ id, printData: { ...printData } });
    if (!response.status) return;
    fetchData({ status: null, page: 1 });
  };

  const handleTableChange = (params) => {
    setPagination({ ...params });
    fetchData({ status: null, page: params.current });
  };

  return (
    <div className='status-order'>
      <Tabs defaultActiveKey={currentTab.slug} onChange={callback}>
        {tabs.map((tab) => (
          <TabPane key={tab.slug} tab={tab.name}>
            <Table
              loading={loadingTable}
              pagination={pagination}
              columns={columns}
              dataSource={dataTable}
              onChange={handleTableChange}
            />
          </TabPane>
        ))}
      </Tabs>
      <Modal
        title='Thông Tin Vé Số'
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <InfoLottery dataInfo={currentData} />
      </Modal>
    </div>
  );
};

export default StatusOrder;
