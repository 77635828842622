import React, { useState } from 'react';
import { Button, Form, Input, notification } from 'antd';
import BaseAPI from '../../utils/BaseAPI';
import get from 'lodash/get';
import './style.scss';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { handleUserLogin } from '../../store/reducers/userReducer';

const LoginScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = useForm();

  const onLogin = async ({ phoneNumber, password }) => {
    try {
      setIsLoading(true);
      const response = await BaseAPI.post('user/login', {
        phoneNumber,
        password
      });
      const role = get(response, 'user.role');
      setIsLoading(false);

      if (role) {
        dispatch(handleUserLogin(response));
        history.push('/');
        return notification.success({
          description: 'Đăng nhập thành công!',
          placement: 'bottomRight'
        });
      }
      form.resetFields();
      return notification.error({
        description: 'Tài khoản không có quyền truy cập quản lý.',
        placement: 'bottomRight'
      });
    } catch (e) {
      form.resetFields();
      notification.error({ description: e.message, placement: 'bottomRight' });
    }
    setIsLoading(false);
  };

  return (
    <div className='login-screen'>
      <h1>Đăng Nhập Quản Lý</h1>
      <div className='form-card-container'>
        <Form form={form} layout='vertical' name='loginForm' onFinish={onLogin}>
          <Form.Item
            label='Số điện thoại'
            name='phoneNumber'
            rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
          >
            <Input placeholder='Nhập số điện thoại' />
          </Form.Item>
          <Form.Item
            label='Mật khẩu'
            name='password'
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
          >
            <Input placeholder='Nhập mật khẩu' type='password' />
          </Form.Item>
          <div className='btn-containers'>
            <Button loading={isLoading} type='primary' htmlType='submit'>
              Đăng nhập
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginScreen;
