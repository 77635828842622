/* eslint-disable no-shadow */
import { CreditCardOutlined, ShoppingCartOutlined, UserAddOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import { get, set } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BaseAPI from 'utils/BaseAPI';
import StatisticChart from './Components/StatisticChart';

const DashboardScreen = () => {
  const [totalLotteries, setTotalLotteries] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalPrice, seteTotalPrice] = useState(0);
  
  const userRedux = useSelector((state) => state.user);
  const token = get(userRedux, 'user.token', '');

  const fetchData = async () => {
    try {
      const response = await BaseAPI.get('/admin');

      const { status, data } = response;
      // const status = get(response, 'status', '');
      // const data = get(response, 'data', '');
      if (status) {
        setTotalLotteries(data.newLotteries || 0);
        setTotalUser(data.newUsers || 0);
        seteTotalPrice(data.totalPrice || 0);
      } else {
        window.location.reload();
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    if(token) fetchData();
  }, [token]);

  return (
    <div>
      <Row gutter={24}>
        <Col span={8}>
          <Card>
            <Statistic
              title='Vé bán trong ngày'
              value={totalLotteries}
              prefix={<ShoppingCartOutlined />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title='Tổng số tiền vé' value={totalPrice} prefix={<CreditCardOutlined />} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title='Khách hàng mới' value={totalUser} prefix={<UserAddOutlined />} />
          </Card>
        </Col>
      </Row>

      <Card className='mt2' style={{ maxWidth: '100%', overflow: 'hidden', maxHeight: '300' }}>
        <h4>Biểu đồ thống kê vé bán</h4>
        <StatisticChart />
      </Card>

      <div className='mt2 flex justify-between'>
        <div>@ 2020</div>
        <div>KenoClub Manager: v1.0</div>
      </div>
    </div>
  );
};

export default DashboardScreen;
