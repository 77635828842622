import { Button, notification, Table } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import BaseAPI from 'utils/BaseAPI'


const fetchResultData = async (type, page) => {
    return BaseAPI.get(`/${type}${type === 'keno' ? '/all' : ''}?sort=-ky&limit=10&page=${page}`)
}

const ResultList = ({ type }) => {
    const queryClient = useQueryClient()
    const [page, setPage] = useState(1)
    const query = useQuery([`result${type}`, page], () => fetchResultData(type, page))
    const [isRewarding,setIsRewarding] = useState(false)
    

    const { data, isLoading } = query;


    const onGiveReward = async (ky) => {
        setIsRewarding(true)
        try{
            const result = await BaseAPI.post("/admin/giveReward", { ky })
            queryClient.invalidateQueries([`result${type}`, page])

            notification.success({
                message:"Hoàn tất",
                description:`Đã trả thưởng cho kỳ #${ky}`
            })
        } catch(e){
            notification.error({
                message:"Lỗi",
                description:"Vui lòng thử lại sau ít phút!"
            })
        } finally{
            setIsRewarding(false)
        }
    }


    const renderColumn = [
        {
            title: 'Kỳ',
            dataIndex: 'ky',
            key: 'ky',
          },
          {
            title: 'Kết quả',
            dataIndex: 'result',
            key: 'result',
            render: (cell) => {
                return <div>
                    <ul style={{display:'flex', flexWrap:"wrap", gap: 4}}>
                        {cell?.map(it=> <li style={{listStyle:"none", background:'red',color:'white',width: 24, height: 24, borderRadius:"50%", justifyContent:"center", alignItems:"center",display:"flex"}}>{it}</li>)}
                    </ul>
                </div>
            }
          },
          
    ]

    if(type === "keno"){
        renderColumn.push({
            title:"Trả thưởng",
            dataIndex: 'ky',
            key:'reward',
            render: (cell, row) => {
                return <div>
                    <Button type={row.isPaid && "primary"} loading={isRewarding} onClick={() => onGiveReward(cell)}>{row.isPaid ? 'Đã ' : ''}Trả thưởng</Button>
                </div>
            }
          })
    }

    return (
        <Table columns={renderColumn} loading={isLoading} pagination={{
                current:page,
                total: data?.total,
                pageSize: 10,
                onChange: p => setPage(p),
                showSizeChanger:false
        }} dataSource={data?.data.docs.filter(it => it.result.length > 0) || []}/>
    )
}

export default ResultList