const SET_USER = 'SET_USER';
const LOGOUT = 'LOGOUT';

const defaultState = {};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export default userReducer;

export const handleUserLogin = (userInformation) => (dispatch) => {
  dispatch({ type: SET_USER, payload: userInformation });
};

export const handleUserLogout = (dispatch) => {
  dispatch({ type: LOGOUT });
};
