import { DatePicker, Empty, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import BaseAPI from "utils/BaseAPI";
import moment from "moment";
import "moment/locale/vi";

const StatsScreen = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState([
    moment().subtract(7, "day"),
    moment()
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await BaseAPI.post("/admin/stats", {
      dFrom: current[0],
      dTo: current[1]
    });

    const { status, data } = response;

    if (status) {
      setList(data);
    }
    setIsLoading(false);
  };

  const onChangeDate = (d) => {
    setCurrent(d);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Ngày",
      dataIndex: "_id",
      key: "_id",
      render: ({ day, month, year }) =>
        moment().locale("vi").date(day).month(month-1).year(year).format("LL")
    },
    {
      title: "Số vé in",
      dataIndex: "totalLotteries",
      key: "totalLotteries"
    },
    {
      title: "Tổng số tiền",
      dataIndex: "totalSalesPrice",
      key: "totalSalesPrice",
      render: (cell) =>
        new Intl.NumberFormat("vi-VN", {
          style: "currency",
          currency: "VND"
        }).format(cell)
    }
  ];
  return (
    <div>
      <div className="keyword-row">
        <h1>Thống kê theo ngày</h1>
        {/* <div className="search">
          <DatePicker.RangePicker value={current} onChange={onChangeDate} />
        </div> */}
      </div>
      <Table
        loading={isLoading}
        dataSource={[...list]}
        pagination={{ pageSize: 10 }}
        columns={columns}
        locale={{
          emptyText: <Empty description="Chưa có dữ liệu hoặc không tìm thấy" />
        }}
      />
    </div>
  );
};

export default StatsScreen;
