import { Button, Form, Input, InputNumber, notification, Table } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseAPI from '../../../utils/BaseAPI'

import './style.scss'

const UserMoneyScreen = () => {
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [amount, setAmount] = useState(0)
  const [keyword, setKeyword] = useState(null)
  const [pNumber, setPNumber] = useState(null)
  const [pAmount, setPAmount] = useState(null)
  const [cashLoading, setCashLoading] = useState(false)
  const [withdraw, setWithdraw] = useState(false)
  const [reason, setReason] = useState(null)

  const history = useHistory()
  const { token, user } = useSelector((state) => state.user)
  const [form] = useForm()
  const fetchUsers = async () => {
    setIsLoading(true)
    try {
      const response = await BaseAPI.get('/user/all?limit=100000')

      if (response.status) {
        setList(response.data.docs)
      }
    } catch (e) {}
    setIsLoading(false)
  }

  const computedList = useMemo(() => {
    if (!keyword) {
      return list
    }

    return list.filter(
      (it) =>
        it.phoneNumber.indexOf(keyword) >= 0 || it.name.indexOf(keyword) >= 0
    )
  }, [keyword, list])

  const onChangeKeyword = (e) => {
    const { value } = e.target
    setKeyword(value)
  }

  const onCashIn =
    (id, isWithdraw = false) =>
    () => {
      setAmount(0)
      setWithdraw(!!isWithdraw)
      setSelectedId(id)
      const findNumber = list.find((it) => it._id === id)

      setPNumber(findNumber.phoneNumber)
      setPAmount(findNumber.balance)
      form.setFieldsValue({ reason: 'Rút tiền từ KenoClub' })
      setIsModal(true)
    }

  const onSubmitCashIn = async () => {
    if (withdraw && parseFloat(pAmount) < parseFloat(amount)) {
      return notification.error({
        title: 'Lỗi',
        description: 'Số tiền rút cao hơn số tiền hiện có',
        placement: 'bottomRight',
      })
    } else if (parseFloat(amount) < 10000) {
      return notification.error({
        title: 'Lỗi',
        description: 'Bạn phải nhập tối thiểu 10.000',
        placement: 'bottomRight',
      })
    }

    setCashLoading(true)
    try {
      if (parseFloat(amount) < 10000) {
        return notification.error({
          title: 'Lỗi',
          description: 'Bạn phải nhập tối thiểu 10.000',
          placement: 'bottomRight',
        })
      }
      await BaseAPI.post(withdraw ? 'user/credit/sub' : 'user/credit', {
        id: selectedId,
        amount,
        reason,
      })

      const findNumber = list.find((it) => it._id === selectedId)

      notification.success({
        description: `${
          withdraw ? 'Đã rút' : 'Đã nạp'
        }: ${new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(amount)} ${withdraw ? 'từ' : 'cho'} SĐT ${
          findNumber.phoneNumber
        }`,
        placement: 'bottomRight',
      })

      fetchUsers()
      setIsModal(false)
      setAmount(null)
    } catch (e) {
      notification.error({
        description: 'Xảy ra lỗi, vui lòng thử lại!',
        placement: 'bottomRight',
      })
    }

    setCashLoading(false)
  }

  const handleCancelModal = () => {
    setIsModal(false)
    form.resetFields()
  }
  const onFinishFailed = () => {
    notification.error({
      title: 'Lỗi',
      description: 'Vui lòng kiểm tra thông tin đã nhập!',
      placement: 'bottomRight',
    })
  }
  useEffect(() => {
    if (!token) {
      history.push('/')
    }
    fetchUsers()
  }, [token, history])

  const columns = [
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số dư',
      dataIndex: 'balance',
      key: 'balance',
      render: (cell) =>
        new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(cell),
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      align: 'right',
      render: (id, row) => {
        let canDeposit = true
        let canWithdraw = true
        console.log(user)
        if (user.role !== 'admin') {
          canDeposit = user.permission?.some((it) => it === 'deposit')
          canWithdraw = user.permission?.some((it) => it === 'withdraw')
        }
        return (
          <>
            {canDeposit && (
              <Button type='primary' onClick={onCashIn(id)}>
                Nạp tiền
              </Button>
            )}
            {canWithdraw && (
              <Button
                disabled={row.balance === 0}
                style={{ marginLeft: 24 }}
                type='danger'
                onClick={onCashIn(id, true)}
              >
                Rút tiền
              </Button>
            )}
          </>
        )
      },
    },
  ]

  return (
    <div>
      <div className='keyword-row'>
        <h1>Danh sách khách hàng</h1>
        <div className='search'>
          <Input.Search
            placeholder='Nhập SĐT Hoặc tên'
            onChange={onChangeKeyword}
          />
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={[...computedList]}
        pagination={{ pageSize: 10 }}
        columns={columns}
      />

      <Modal
        confirmLoading={cashLoading}
        title={
          withdraw
            ? `Rút tiền tài khoản ${pNumber}`
            : `Nạp tiền cho số điện thoại: ${pNumber}`
        }
        visible={isModal}
        onCancel={handleCancelModal}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          onFinish={onSubmitCashIn}
          onFinishFailed={onFinishFailed}
        >
          <div style={{ marginBottom: 24 }}>
            <InputNumber
              style={{ width: '100%' }}
              disabled={cashLoading}
              value={amount}
              formatter={(value) => new Intl.NumberFormat().format(value)}
              parser={(value) => value.split(',').join('').split('.').join('')}
              onChange={(value) => setAmount(value)}
              placeholder='Nhập số tiền'
            />
          </div>
          {withdraw && (
            <Form.Item
              name='reason'
              disabled={cashLoading}
              onChange={(e) => setReason(e.target ? e.target.value : '')}
              rules={[{ required: true, message: 'Vui lòng nhập lý do rút' }]}
            >
              <Input.TextArea placeholder='Nhập thông tin rút' />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  )
}

export default UserMoneyScreen
