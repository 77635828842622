import axios from 'axios';
import { handleUserLogout } from 'store/reducers/userReducer';
import store from '../store';
const BaseAPI = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 120000,
});

BaseAPI.interceptors.request.use((config) => {
  let user = {};
  if (store) {
    user = store.getState().user;
  }
  if (!!user.token) {
    config.headers.Authorization = `Bearer ${user.token}`;
  }
  return config;
});

BaseAPI.interceptors.response.use(
  (response) => {
    const { status } = response.data;
    if (status) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(handleUserLogout);
    }
    Promise.reject(error.response.data);
  },
);

export default BaseAPI;
