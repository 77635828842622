import { Button, Card, DatePicker, Form, Input, Modal, notification, TimePicker } from 'antd';
import React from 'react';
import BaseAPI from '../../utils/BaseAPI';

const PowerSyncScreen = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const onSync = async ({ current, date, time }) => {
    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
    });

    console.log('test-result', date.format('LLLL'));

    setIsLoading(true);

    Modal.confirm({
      title: 'Vui lòng xác nhận đồng bộ',
      content: 'Thao tác này ảnh hưởng đến toàn hệ thống, vui lòng xác nhận ?',
      onOk: async () => {
        try {
          const response = await BaseAPI.get(`sync/all?lotteryType=power`);
          const idSync = response.data ? response.data.docs[0]._id : '';
          await BaseAPI.patch(`sync/${idSync}`, {
            current,
            nextResult: date,
          });

          notification.success({
            title: 'Hoàn tất',
            description: 'Đã đồng bộ thành công!',
            placement: 'bottomRight',
          });
        } catch (e) {
          notification.error({
            title: 'Lỗi',
            description: 'Vui lòng thử lại sau vài phút!',
            placement: 'bottomRight',
          });
        }
      },
    });

    setIsLoading(false);
  };
  return (
    <div className='power-sync-screen'>
      <div className='flex justify-between align-center'>
        <h1>Đồng bộ Power</h1>
      </div>
      <Card className='mt1'>
        <Form layout='vertical' name='syncForm' onFinish={onSync}>
          <Form.Item
            label='Kỳ đồng bộ'
            name='current'
            rules={[{ required: true, message: 'Nhập kỳ hiện tại' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label='Ngày xổ'
            name='date'
            rules={[{ required: true, message: 'Nhập ngày xổ hiện tại' }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item
            label='Giờ xổ'
            name='time'
            rules={[{ required: true, message: 'Nhập giờ xổ hiện tại' }]}>
            <TimePicker format='HH:mm' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoading}>
              Đồng bộ
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default PowerSyncScreen;
