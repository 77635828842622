import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { Button, Empty, Input, Modal, notification, Table, Form, Upload, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import BaseAPI from '../../../utils/BaseAPI';

import './style.scss';

const AdvertisementScreen = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [id, setId] = useState(null);
  const LIMIT = 10;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LIMIT,
    total: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const fetchData = async ({ page = 1 }) => {
    setIsLoading(true);
    try {
      const response = await BaseAPI.get(`/ads?page=${page}`);
      if (response.status) {
        const { data, total } = response;
        setList(data.docs);
        setPagination({
          ...pagination,
          total,
          current: page,
        });
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleTableChange = (params) => {
    setPagination({ ...params });
    fetchData({ status: null, page: params.current });
  };

  const computedList = useMemo(() => {
    console.log('This is list', list);
    if (!keyword) {
      return list;
    }
    return list.filter((it) => it.name.indexOf(keyword) >= 0 || it.content.indexOf(keyword) >= 0);
  }, [keyword, list]);

  const onChangeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  };

  const handleActiveAdvertise = (isActive, data) => () => {
    isActive === true
      ? Modal.confirm({
          title: 'Xác nhận',
          content: 'Bạn có chắc muốn khóa quảng cáo này ?',
          type: 'warning',
          onOk: async () => {
            try {
              const res = await BaseAPI.put(`/ads/${data._id}`, {
                ...data,
                isActive: !isActive,
              });
              if (res.status && res.data !== '404 Not Found') {
                notification.error({
                  title: 'Thành công',
                  description: 'Đã khóa quảng cáo',
                  placement: 'bottomRight',
                });
              } else
                notification.error({
                  title: 'Lỗi',
                  description: 'Không thể khóa quảng cáo',
                  placement: 'bottomRight',
                });
            } catch (error) {
              notification.error({
                title: 'Lỗi',
                description: 'Xảy ra lỗi mã lỗi -5001',
                placement: 'bottomRight',
              });
            }
            fetchData({ page: 1 });
          },
        })
      : Modal.confirm({
          title: 'Xác nhận',
          content: 'Bạn có chắc muốn mở khóa quảng cáo này ?',
          type: 'warning',
          onOk: async () => {
            try {
              const res = await BaseAPI.put(`/ads/${data._id}`, {
                ...data,
                isActive: !isActive,
              });
              if (res.status && res.data !== '404 Not Found') {
                notification.success({
                  title: 'Thành công',
                  description: 'Quảng cáo đã mở',
                  placement: 'bottomRight',
                });
              } else
                notification.error({
                  title: 'Lỗi',
                  description: 'Mở quảng cáo thất bại',
                  placement: 'bottomRight',
                });
            } catch (error) {
              notification.error({
                title: 'Lỗi',
                description: 'Xảy ra lỗi mã lỗi -5001',
                placement: 'bottomRight',
              });
            }
            fetchData({ page: 1 });
          },
        });
  };

  const handlDeleteAdvertise = (id) => () => {
    Modal.confirm({
      title: 'Xác nhận',
      content: 'Bạn có chắc muốn xoá quảng cáo này ?',
      type: 'warning',
      onOk: async () => {
        try {
          const res = await BaseAPI.delete(`/ads/${id}`);
          if (res.status && res.data !== '404 Not Found') {
            notification.success({
              title: 'Thành công',
              description: 'Quảng cáo đã được xóa',
              placement: 'bottomRight',
            });
          } else
            notification.error({
              title: 'Lỗi',
              description: 'Xóa quảng cáo thất bại',
              placement: 'bottomRight',
            });
        } catch (error) {
          notification.error({
            title: 'Lỗi',
            description: 'Xảy ra lỗi mã lỗi -5001',
            placement: 'bottomRight',
          });
        }
        fetchData({ page: 1 });
      },
    });
  };

  const handleAddEditAdvertise =
    (id, edit = true) =>
    () => {
      setIsEdit(!!edit);
      setId(id);
      if (id) {
        const findItem = list.find((it) => it._id === id);
        form.setFieldsValue({ name: findItem.name, content: findItem.content });
        if (findItem.image) {
          setFileList([
            {
              url: `http://139.180.219.58:3001/public/uploads/${findItem.image}`,
            },
          ]);
        }
      } else form.resetFields();
      setIsVisible(true);
    };

  const onFinish = async (formValues) => {
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('content', formValues.content);
    formData.append('isActive', true);
    if (formValues.image === undefined) {
      formData.append('image', '');
    } else formData.append('image', formValues.image.file.originFileObj);

    if (isEdit) {
      try {
        const response = await BaseAPI.put(`/ads/${id}`, formData);
        if (response.status && response.data !== '404 Not Found') {
          notification.success({
            title: 'Thành công',
            description: 'Cập nhật thành công',
            placement: 'bottomRight',
          });
        } else
          notification.error({
            title: 'Lỗi',
            description: 'Cập nhật thất bại',
            placement: 'bottomRight',
          });
      } catch (e) {
        notification.error({
          title: 'Lỗi',
          description: 'Xảy ra lỗi mã lỗi -5001',
          placement: 'bottomRight',
        });
      }
      setIsVisible(false);
      setFileList([]);
      fetchData({ page: 1 });
    } else {
      try {
        const res = await BaseAPI.post('/ads', formData);
        if (res.status && res.data !== '404 Not Found') {
          notification.success({
            title: 'Thành công',
            description: 'Thêm mới thành công',
            placement: 'bottomRight',
          });
        } else
          notification.error({
            title: 'Lỗi',
            description: 'Thêm mới không thành công',
            placement: 'bottomRight',
          });
      } catch (e) {
        notification.error({
          title: 'Lỗi',
          description: 'Xảy ra lỗi mã lỗi -5001',
          placement: 'bottomRight',
        });
      }
      setIsVisible(false);
      setFileList([]);
      fetchData({ page: 1 });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      title: 'Lỗi',
      description: 'Vui lòng kiểm tra thông tin đã nhập!',
      placement: 'bottomRight',
    });
  };

  const handlePreviewImage = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const handleChangeImage = ({ fileList }) => setFileList(fileList);

  const columns = [
    {
      title: 'Định danh',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      render: (content) => `${content.substring(0, 100)}...`,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, data) =>
        isActive === true ? (
          <Button
            type='primary'
            className='btn-active'
            size='small'
            onClick={handleActiveAdvertise(isActive, data)}>
            Hoạt động
          </Button>
        ) : (
          <Button
            type='danger'
            size='small'
            className='btn-notactive'
            onClick={handleActiveAdvertise(isActive, data)}>
            Đã khoá
          </Button>
        ),
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      align: 'right',
      render: (id) => (
        <>
          <Space>
            <Button type='primary' className='btn-update' onClick={handleAddEditAdvertise(id)}>
              <EditOutlined />
            </Button>
            <Button type='danger' onClick={handlDeleteAdvertise(id)}>
              <DeleteOutlined />
            </Button>
          </Space>
        </>
      ),
    },
  ];
  const handleCancel = () => {
    setFileList([]);
    setIsVisible(false);
  };

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  return (
    <div>
      <div className='keyword-row'>
        <h1>Danh sách quảng cáo</h1>
        <div className='search flex'>
          <Button
            type='primary'
            className='btn-add'
            onClick={handleAddEditAdvertise(null, false)}
            style={{ marginRight: 12 }}>
            Thêm mới
          </Button>
          <Input.Search placeholder='Nhập tên hoặc nội dung' onChange={onChangeKeyword} />
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={[...computedList]}
        pagination={pagination}
        columns={columns}
        onChange={handleTableChange}
        locale={{
          emptyText: <Empty description='Chưa có dữ liệu hoặc không tìm thấy' />,
        }}
      />
      <Modal
        title={isEdit ? 'Sửa quảng cáo' : 'Thêm quảng cáo'}
        visible={isVisible}
        onCancel={handleCancel}
        onOk={() => form.submit()}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            name='name'
            label='Định danh'
            labelCol={{ span: 5 }}
            rules={[
              {
                required: true,
                message: 'Vui lòng đặt tên quảng cáo',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='content'
            label='Nội dung '
            labelCol={{ span: 5 }}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập nội dung quảng cáo',
              },
            ]}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item name='image' label='Hình ảnh' labelCol={{ span: 5 }}>
            <Upload
              action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
              listType='picture-card'
              fileList={fileList}
              onChange={handleChangeImage}
              onPreview={handlePreviewImage}>
              {fileList.length < 1 && (
                <div>
                  <PlusOutlined />
                  <div className='ant-upload-text'>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdvertisementScreen;
