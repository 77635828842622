import React from 'react';
import Chart from 'react-apexcharts';
import BaseAPI from 'utils/BaseAPI';
import moment from 'moment';
import 'moment/locale/vi';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const StatisticChart = () => {
  const [list, setList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const userRedux = useSelector((state) => state.user);

  const token = get(userRedux, 'user.token', '');
  const fetchChart = async () => {
    setIsLoading(true);
    const response = await BaseAPI.post('/admin/stats');

    // const { status, data } = response;
    const status = get(response, 'status', '');
    const data = get(response, 'data', '');
    if (status) {
      setList(data);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (token) fetchChart();
  }, [token]);

  const chartData = {
    options: {
      chart: {
        id: 'basic-bar',
        animation: {
          enabled: false,
        },
        width: '100%',
      },
      xaxis: {
        categories: list.slice(0, 20).map((it) =>
          moment()
            .locale('vi')
            .date(it._id.day)
            .month(it._id.month - 1)
            .year(it._id.year)
            .format('L'),
        ),
      },
    },
    series: [
      {
        name: 'series-1',
        data: list.slice(0, 20).map((it) => it.totalLotteries),
      },
    ],
  };

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type='bar'
      width='100%'
      height={350}
    />
  );
};

export default StatisticChart;
