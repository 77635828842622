/* eslint-disable react/react-in-jsx-scope */
import { HomeOutlined, LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons'

const data = [...Array(10)].fill(1).map((it, index) => {
  return {
    id: index,
    title: '233432',
    content: '12312312'
  }
})

const sidebar = [
  {
    title: 'Trang thống kê',
    key: '/dashboard',
    icon: <HomeOutlined/>
  },
  {
    title: 'Khách hàng',
    key: '/user',
    icon: <UserOutlined />,
    subMenu: [
      {
        title: 'Nạp / Rút tiền',
        key: '/user/money',
        permission: ['deposit', 'withdraw']
      },
      {
        title: 'Danh sách tài khoản',
        key: '/user/list',
        permission: ['userList']
      },
      {
        title: 'Tài khoản Staffs',
        key: '/user/new',
        permission: ['admin']
      },
      {
        title: 'Tài khoản bị khoá',
        key: '/user/blocked',
        permission: ['banUser']
      },
      {
        title: 'Lịch sử giao dịch',
        key: '/user/history-transaction',
        permission: ['transactionList']
      },
      {
        title: 'Lịch sử rút tiền',
        key: '/user/withdraw-history',
        permission: ['withdrawList']
      }
    ]
  },
  {
    title: 'Bán hàng',
    key: '/sale',
    icon: <LaptopOutlined />,
    subMenu: [
      {
        title: 'Thống kê bán hàng',
        key: '/stats',
        permission: ['saleBoard']
      },
      {
        title: 'Trả thưởng',
        key: '/tra-thuong',
        permission: ['admin']
      },
      {
        title: 'Lịch sử vé bán',
        key: '/history',
        permission: ['saleList']
      },
      {
        title: 'Vé Mua Từ Web',
        key: '/status-order',
        permission: ['saleListWeb']
      },
      {
        title: 'Đại lý - CD-KEY',
        key: '/agency',
        permission: ['admin']
      }
    ]
  },
  {
    title: 'Mở rộng',
    key: '/expandable',
    icon: <NotificationOutlined />,
    subMenu: [
      {
        title: 'Kết quả sổ xố',
        key: '/result',
        permission: ['ticket']
      },
      {
        title: 'Vé truyền thống',
        key: '/ticket',
        permission: ['ticket']
      },
      {
        title: 'Quảng cáo',
        key: '/ads',
        permission: ['admin']
      },
      {
        title: 'Gửi thông báo',
        key: '/send-notice',
        permission: ['admin']
      },
      {
        title: 'Đồng bộ Keno',
        key: '/keno-sync',
        permission: ['admin']
      },
      {
        title: 'Đồng bộ Mega',
        key: '/mega-sync',
        permission: ['admin']
      },
      {
        title: 'Đồng bộ 3D Pro',
        key: '/3d-pro-sync',
        permission: ['admin']
      },
      {
        title: 'Đồng bộ Power',
        key: '/power-sync',
        permission: ['admin']
      },
      {
        title: 'Cơ cấu',
        key: '/rule',
        permission: ['admin']
      },
      {
        title: 'Cấu hình',
        key: '/config',
        permission: ['admin']
      }
    ]
  }
]

export default sidebar
