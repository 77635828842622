import { Button, message, Spin, Table } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import BaseAPI from 'utils/BaseAPI'


const fetchList = async (page) => {
    return BaseAPI.get(`/lottery/all?totalWin[gte]=10000000&limit=10&page=${page}`)
}




const RewardScreen = () => {
    const queryClient = useQueryClient()
    const [page, setPage] = useState(1)
    const { isLoading, data } = useQuery(['rewards', page], () => fetchList(page))



    const mutation = useMutation(async (data) => {
        const updateObject = {
            isPaid: true,
            method: 'account',
        };
        return BaseAPI.post(`lottery/pay/${data.ticketId}`, updateObject);
    }, {
        onSuccess: () => {
            message.success("Đã trả thưởng thành công!")
            queryClient.invalidateQueries(["rewards", page])
        }
    })

    const onPay = (ticketId) => {
        return mutation.mutate({ ticketId })
    }

    const columns = [
        {
            title: 'Số Điện Thoại',
            dataIndex: 'customer.phoneNumber',
            key: 'customerPhoneNumber',
            render: (cell, row) => row.customer?.phoneNumber
        },
        {
            title: 'Tên KH',
            dataIndex: 'customer.name',
            key: 'customerName',
            render: (cell, row) => row.customer?.name
        },
        {
            title: 'Giải thưởng',
            dataIndex: 'totalWin',
            key: 'totalWin',
            render: cell => new Intl.NumberFormat().format(cell) + 'đ'
        },
        {
            title: 'Thao Tác',
            dataIndex: 'totalWin',
            key: 'totalWin',
            align: "right",
            render: (cell, row) => {
                return <Button type="primary" onClick={()=> onPay(row._id)}>Trả thưởng</Button>
            }
        },

    ]



    const renderData = _.filter(data?.data.docs || [], it=> it.totalWin > 0)

    return (
        <div>
            <h1 style={{fontSize: 20}}>Trả thưởng vé trúng mệnh giá cao</h1>
            <Table pagination={{ pageSize: 10, current: page, total: data?.total || 0, onChange: p => setPage(p) }} loading={isLoading} dataSource={renderData} columns={columns} />
        </div>
    )
}

export default RewardScreen