import React, { useEffect, useState } from "react";
import { getTypeLottery } from "../../../constant/status-vietlott";
import { formatCurrency } from "../../../utils/format-currency";
import { get } from "lodash";
import "./InfoLottery.scss";

const InfoLottery = ({ dataInfo }) => {
  const [infoUser, setInfoUser] = useState({});
  const [lottery, setLottery] = useState([]);

  useEffect(() => {
    setLottery(JSON.parse(`${get(dataInfo, "data.lottery", "")}`));
    setInfoUser(get(dataInfo, "data.customer", {}));
  }, [get(dataInfo, "data._id", "")]);
  return (
    <div className="info-lottery">
      <div className="info-lottery__user">
        <div className="item">
          <p>
            Họ Tên: <span>{get(infoUser, "name", "")}</span>
          </p>
        </div>
        <div className="item">
          <p>
            Số Điện Thoại: <span>{get(infoUser, "phoneNumber", "")}</span>
          </p>
        </div>
        <div className="item">
          <p>
            Địa chỉ: <span>{get(dataInfo, "data.address", "")}</span>
          </p>
        </div>
      </div>
      <div className="info-lottery__lottery">
        <div className="lottery-info">
          <div className="lottery-info__item">
            Loại Vé: <span>{getTypeLottery(get(dataInfo, "type", ""))}</span>
          </div>
          <div className="lottery-info__item">
            Bao: <span>{get(lottery[0], "nums", []).length}</span>
          </div>
          <div className="lottery-info__item">
            Số Kỳ chơi: <span>{get(lottery[0], "numberPeriods", "")}</span>
          </div>
          <div className="lottery-info__item">
            Tổng tiền:{" "}
            <span>{formatCurrency(get(dataInfo, "data.totalPrice", ""))}</span>
          </div>
        </div>
        {lottery.map((line, index) => (
          <div key={index} className="lottery-number">
            {get(line, "nums", []).map((number, numberIndex) => (
              <div key={numberIndex} className="lottery-number__select">
                <span>{number}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoLottery;
