import { Button, Card, Col, Form, Input, notification, Row } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import BaseAPI from "utils/BaseAPI";
import "./style.scss";

const AdvertisementFormScreen = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const { id, content } = location.state;
  const isEdit = !!id;
  const history = useHistory();
  const onFinish = async (formValues) => {
    if (isEdit) {
      try {
        await BaseAPI.put(`/ads/${id}`, formValues);
        notification.success({
          title: "Thành công",
          description: "Cập nhật thành công",
          placement: "bottomRight",
        });
        history.push("/ads");
      } catch (e) {
        notification.error({
          title: "Lỗi",
          description: "Xảy ra lỗi mã lỗi -5001",
          placement: "bottomRight",
        });
      }
    } else
      try {
        await BaseAPI.post("/ads", formValues);
        notification.success({
          title: "Thành công",
          description: "Thêm mới thành công",
          placement: "bottomRight",
        });
        history.push("/ads");
      } catch (e) {
        notification.error({
          title: "Lỗi",
          description: "Xảy ra lỗi mã lỗi -5001",
          placement: "bottomRight",
        });
      }
  };
  const onFinishFailed = () => {
    notification.error({
      title: "Lỗi",
      description: "Vui lòng kiểm tra thông tin đã nhập!",
      placement: "bottomRight",
    });
  };
  return (
    <div className='ads-form-screen'>
      <div className='flex justify-center'>
        <h1>{isEdit ? "Sửa quảng cáo" : "Thêm quảng cáo"}</h1>
      </div>

      <Card>
        <Row>
          <Col span={6}></Col>
          <Col span={12}>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item name='name' label='Định danh'>
                {isEdit ? (
                  <Input
                    defaultValue={content.name}
                    initialValues={content.name}
                  />
                ) : (
                  <Input
                    rules={[
                      { required: true, message: "Vui lòng đặt tên quảng cáo" },
                    ]}
                  />
                )}
              </Form.Item>
              <Form.Item name='content' label='Nội dung '>
                {isEdit ? (
                  <Input.TextArea
                    defaultValue={content.content}
                    initialValues={content.content}
                  />
                ) : (
                  <Input.TextArea
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập nội dung quảng cáo",
                      },
                    ]}
                  />
                )}
              </Form.Item>
              <Form.Item name='image' label='Hình ảnh'>
                <Input type='file' />
              </Form.Item>
              <Form.Item>
                <div className='flex justify-center'>
                  {isEdit ? (
                    <Button className='btn-update' onClick={form.submit}>
                      Cập nhật
                    </Button>
                  ) : (
                    <Button className='btn-add' onClick={form.submit}>
                      Thêm mới
                    </Button>
                  )}
                </div>
              </Form.Item>
            </Form>
          </Col>
          <Col span={6}></Col>
        </Row>
      </Card>
    </div>
  );
};

export default AdvertisementFormScreen;

//////
