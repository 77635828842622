import AdvertisementFormScreen from "pages/Advertisement/AdvertisementFormScreen";
import AdvertisementScreen from "pages/Advertisement/AdvertisementScreen";
import AgencyScreen from "pages/Agency/AgencyScreen";
import ConfigScreen from "pages/ConfigScreen";
import DashboardScreen from "pages/DashboardScreen";
import HistoryScreen from "pages/HistoryScreen";
import Hscreen from "pages/HScreen";
import KenoSyncScreen from "pages/KenoSyncScreen";
import MegaSyncScreen from "pages/MegaSyncScreen";
import PowerSyncScreen from "pages/PowerSyncScreen";
import Pro3DSync from "pages/Pro3DSyncScreen";
import ResultScreen from "pages/ResultScreen";
import RewardScreen from "pages/RewardScreen";
import RuleScreen from "pages/RuleScreen";
import SendNotificationScreen from "pages/SendNotificationScreen";
import StatsScreen from "pages/StatsScreen";
import StatusOrder from "pages/StatusOrder";
import TicketScreen from "pages/TicketScreen";
import UserBlockedScreen from "pages/User/UserBlockedScreen";
import UserListScreen from "pages/User/UserListScreen";
import UserMoneyScreen from "pages/User/UserMoneyScreen";
import UserNewScreen from "pages/User/UserNewScreen";
import UserTransactionScreen from "pages/User/UserTransactionScreen";
import UserWithdrawScreen from "pages/User/UserWithdrawScreen";
import { Redirect } from "react-router-dom";

const routes = [
  {
    path: ["/result"],
    component: ResultScreen,
    permission: ["ticket"],
  },
  //User routes
  {
    path: "/user/money",
    component: UserMoneyScreen,
    permission: ["deposit", "withdraw"],
  },
  {
    path: "/user/list",
    component: UserListScreen,
    permission: ["userList"],
  },
  {
    path: "/user/blocked",
    component: UserBlockedScreen,
    permission: ["banUser"],
  },
  {
    path: ["/user/new", "/user/new/:id"],
    component: UserNewScreen,
    permission: ["admin"],
  },
  {
    path: ["/user/history-transaction"],
    component: UserTransactionScreen,
    permission: ["transactionList"],
  },
  {
    path: ["/user/withdraw-history"],
    component: UserWithdrawScreen,
    permission: ["withdrawList"],
  },
  // Sale Routes
  {
    path: ["/stats"],
    component: StatsScreen,
    permission: ["saleBoard"],
  },
  {
    path: ["/history"],
    component: HistoryScreen,
    permission: ["saleList"],
  },
  {
    path: ["/status-order"],
    component: StatusOrder,
    permission: ["saleListWeb"],
  },
  // Agency
  {
    path: ["/agency"],
    component: AgencyScreen,
    permission: ["admin"],
  },
  {
    path: ["/tra-thuong"],
    component: RewardScreen,
    permission: ["admin"],
  },
  // Advertisement
  {
    path: ["/ads"],
    component: AdvertisementScreen,
    permission: ["admin"],
  },
  {
    path: ["/ads/form"],
    component: AdvertisementFormScreen,
    permission: ["admin"],
  },
  // Configuration Routes
  {
    path: ["/keno-sync"],
    component: KenoSyncScreen,
    permission: ["admin"],
  },
  {
    path: ["/mega-sync"],
    component: MegaSyncScreen,
    permission: ["admin"],
  },
  {
    path: ["/power-sync"],
    component: PowerSyncScreen,
    permission: ["admin"],
  },
  {
    path: ["/3d-pro-sync"],
    component: Pro3DSync,
    permission: ["admin"],
  },
  {
    path: ["/rule"],
    component: RuleScreen,
    permission: ["admin"],
  },
  {
    path: ["/send-notice"],
    component: SendNotificationScreen,
    permission: ["admin"],
  },
  //Traditional Routes
  {
    path: ["/ticket"],
    component: TicketScreen,
    permission: ["ticket"],
  },
  {
    path: ["/config"],
    component: ConfigScreen,
    permission: ["ticket"],
  },
  {
    path: ["/hconfig"],
    component: Hscreen,
    permission: ["admin"],
  },
  // Others Routes
  {
    path: "/",
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: "/dashboard",
    component: DashboardScreen,
  },
];

export default routes;
