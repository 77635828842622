import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Table,
  Upload,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import BaseAPI from 'utils/BaseAPI';
import './style.scss';

const RuleScreen = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [id, setId] = useState(null);
  const LIMIT = 10;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LIMIT,
    total: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const { Option } = Select;
  const listSelectRule = [
    { value: 'keno' },
    { value: 'mega' },
    { value: 'power' },
    { value: '3 miền' },
  ];
  const fetchData = async ({ page = 1 }) => {
    setIsLoading(true);
    try {
      const response = await BaseAPI.get(`/ads?page=${page}`);
      if (response.status) {
        const { data, total } = response;
        setList(data.docs);
        setPagination({
          ...pagination,
          total,
          current: page,
        });
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleTableChange = (params) => {
    setPagination({ ...params });
    fetchData({ status: null, page: params.current });
  };

  const computedList = useMemo(() => {
    if (!keyword) {
      return list;
    }
    return list.filter((it) => it.name.indexOf(keyword) >= 0 || it.content.indexOf(keyword) >= 0);
  }, [keyword, list]);

  const onChangeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  };

  const handlDeleteRule = (id) => () => {
    Modal.confirm({
      title: 'Xác nhận',
      content: 'Bạn có chắc muốn xoá cơ cấu này ?',
      type: 'warning',
      onOk: async () => {
        try {
          const res = await BaseAPI.delete(`/ads/${id}`);
          if (res.status && res.data !== '404 Not Found') {
            notification.success({
              title: 'Thành công',
              description: 'Cơ cấu đã được xóa',
              placement: 'bottomRight',
            });
          } else
            notification.error({
              title: 'Lỗi',
              description: 'Xóa cơ cấu thất bại',
              placement: 'bottomRight',
            });
        } catch (error) {
          notification.error({
            title: 'Lỗi',
            description: 'Xảy ra lỗi mã lỗi -5001',
            placement: 'bottomRight',
          });
        }
        fetchData({ page: 1 });
      },
    });
  };

  const handleAddEditRule =
    (id, edit = true) =>
    () => {
      setIsEdit(!!edit);
      setId(id);
      if (id) {
        const findItem = list.find((it) => it._id === id);
        form.setFieldsValue({ name: findItem.name, content: findItem.content });
        if (findItem.image) {
          setFileList([
            {
              url: `http://139.180.219.58:3001/public/uploads/${findItem.image}`,
            },
          ]);
        }
      } else form.resetFields();
      setIsVisible(true);
    };

  const onFinish = async (formValues) => {
    // const formData = new FormData();
    // formData.append('name', formValues.name);
    // formData.append('content', formValues.content);
    // formData.append('isActive', true);
    // if (formValues.image === undefined) {
    //   formData.append('image', '');
    // } else formData.append('image', formValues.image.file.originFileObj);
    // if (isEdit) {
    //   try {
    //     const response = await BaseAPI.put(`/ads/${id}`, formData);
    //     if (response.status && response.data !== '404 Not Found') {
    //       notification.success({
    //         title: 'Thành công',
    //         description: 'Cập nhật thành công',
    //         placement: 'bottomRight',
    //       });
    //     } else
    //       notification.error({
    //         title: 'Lỗi',
    //         description: 'Cập nhật thất bại',
    //         placement: 'bottomRight',
    //       });
    //   } catch (e) {
    //     notification.error({
    //       title: 'Lỗi',
    //       description: 'Xảy ra lỗi mã lỗi -5001',
    //       placement: 'bottomRight',
    //     });
    //   }
    //   setIsVisible(false);
    //   setFileList([]);
    //   fetchData({ page: 1 });
    // } else {
    //   try {
    //     const res = await BaseAPI.post('/ads', formData);
    //     if (res.status && res.data !== '404 Not Found') {
    //       notification.success({
    //         title: 'Thành công',
    //         description: 'Thêm mới thành công',
    //         placement: 'bottomRight',
    //       });
    //     } else
    //       notification.error({
    //         title: 'Lỗi',
    //         description: 'Thêm mới không thành công',
    //         placement: 'bottomRight',
    //       });
    //   } catch (e) {
    //     notification.error({
    //       title: 'Lỗi',
    //       description: 'Xảy ra lỗi mã lỗi -5001',
    //       placement: 'bottomRight',
    //     });
    //   }
    //   setIsVisible(false);
    //   setFileList([]);
    //   fetchData({ page: 1 });
    // }
  };

  const onFinishFailed = () => {
    notification.error({
      title: 'Lỗi',
      description: 'Vui lòng kiểm tra thông tin đã nhập!',
      placement: 'bottomRight',
    });
  };

  const handlePreviewImage = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const handleChangeImage = ({ fileList }) => setFileList(fileList);

  const columns = [
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      align: 'right',
      render: (id) => (
        <>
          <Space>
            <Button type='primary' className='btn-update' onClick={handleAddEditRule(id)}>
              <EditOutlined />
            </Button>
            <Button type='danger' onClick={handlDeleteRule(id)}>
              <DeleteOutlined />
            </Button>
          </Space>
        </>
      ),
    },
  ];
  const handleCancel = () => {
    setFileList([]);
    setIsVisible(false);
  };

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  return (
    <div>
      <div className='keyword-row'>
        <h1>Danh sách cơ cấu</h1>
        <div className='search flex'>
          <Button
            type='primary'
            className='btn-add'
            onClick={handleAddEditRule(null, false)}
            style={{ marginRight: 12 }}>
            Thêm mới
          </Button>
          <Input.Search placeholder='Nhập tên hoặc nội dung' onChange={onChangeKeyword} />
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={[...computedList]}
        pagination={pagination}
        columns={columns}
        onChange={handleTableChange}
        locale={{
          emptyText: <Empty description='Chưa có dữ liệu hoặc không tìm thấy' />,
        }}
      />
      <Modal
        title={isEdit ? 'Sửa cơ cấu' : 'Thêm cơ cấu'}
        visible={isVisible}
        onCancel={handleCancel}
        onOk={() => form.submit()}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            name='name'
            label='Tên'
            labelCol={{ span: 4 }}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên cơ cấu',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item name='type' label='Loại' labelCol={{ span: 4 }}>
            <Select defaultValue='keno' style={{ width: 120 }}>
              {listSelectRule.map((item, index) => {
                return (
                  <Option value={item.value} key={index}>
                    {item.value}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name='link' label='Link' labelCol={{ span: 4 }}>
            <Input />
          </Form.Item>

          <Form.Item name='image' label='Hình ảnh' labelCol={{ span: 4 }}>
            <Upload
              action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
              listType='picture-card'
              fileList={fileList}
              onChange={handleChangeImage}
              onPreview={handlePreviewImage}>
              {fileList.length < 1 && (
                <div>
                  <PlusOutlined />
                  <div className='ant-upload-text'>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item name='videoLink' label='Video' labelCol={{ span: 4 }}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RuleScreen;
