import { Button, Empty, Input, Modal, notification, Table, Tag } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import {
  getColorStatusLottery,
  getStatusLottery,
} from '../../../constant/status-vietlott'
import BaseAPI from '../../../utils/BaseAPI'
import './styles.scss'

const UserWithdrawScreen = () => {
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState(null)

  const fetchUsers = async () => {
    
    if(list.length > 0) return  setIsLoading(false)
    setIsLoading(true)
    try {
      const response = await BaseAPI.get(`/withdraw/all?limit=10000000000`)
      if (!response.status) return
      const { data } = response
      const arrTemp = get(data, 'docs', []).map((item, index) => ({
        ...item,
        key: index,
        name: get(item, 'user.name', 'name default'),
        phoneNumber: get(item, 'user.phoneNumber', 'number default'),
        status: item.status,
        date: item.createdAt,
        amount: item.amount,
        _id: item._id,
      }))
      setList(arrTemp)
    } catch (e) {}
    setIsLoading(false)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const onConfirmWithdraw = (id, row) => () => {
    const numOfMoney = new Intl.NumberFormat().format(row.amount) + 'đ'
    Modal.confirm({
      title: 'Xác nhận thanh toán',
      content: `Bạn có muốn xác nhận thanh toán ${numOfMoney} cho tài khoản ${row.phoneNumber} ?`,
      onOk: async () => {
        try {
          const response = await BaseAPI.post(`/withdraw/one/${id}`)
          const { data } = response
          if (!response.status) return
          const objIndex = list.findIndex((obj) => obj._id === data.process._id)
          setList([
            ...list.slice(0, objIndex),
            { ...list[objIndex], status: data.process.status },
            ...list.slice(objIndex + 1),
          ])
        } catch (e) {
          notification.error({
            title: 'Lỗi',
            description: 'Vui lòng thử lại sau ít phút',
            placement: 'bottomRight',
          })
        }
      },
    })
  }

  const onChangeKeyword = (e) => {
    const { value } = e.target
    setKeyword(value)
  }

  const computedList = useMemo(() => {
    if (!keyword) {
      return list
    }
    return list.filter((it) => it.phoneNumber.indexOf(keyword) >= 0)
  }, [keyword, list])

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => (
        <div style={{ color: 'red', fontWeight:"bold" }}>
          {new Intl.NumberFormat().format(value) + 'đ'}
        </div>
      ),
    },
    {
      title: 'Thông tin',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (info, row) => {
        return (
          <div>
            <div>Hình thức: <b>{row.withdrawType}</b></div>
            <div>Tài khoản: <b>{row.withdrawTo}</b></div>
            <div>Chủ tài khoản: <b>{row.info}</b></div>
          </div>
        )
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <>
          <Tag color={getColorStatusLottery(status)}>
          {console.log("🚀 ~ file: index.js ~ line 107 ~ UserWithdrawScreen ~ getColorStatusLottery(status)", getColorStatusLottery(status))}
            {getStatusLottery(status)}
          </Tag>
        </>
      ),
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
      render: (date) => <p>{moment(date).locale('vi').format('LL')}</p>,
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      key: '_id',
      align: 'left',
      render: (_id, row) => (
        <Button
          type='primary'
          disabled={row.status === 'done'}
          onClick={onConfirmWithdraw(_id, row)}
        >
          Xác nhận
        </Button>
      ),
    },
  ]

  return (
    <div>
      <div className='keyword-row'>
        <h1>Lịch sử rút tiền</h1>
        <div className='search'>
          <Input.Search
            placeholder='Nhập số điện thoại '
            onChange={onChangeKeyword}
          />
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={[...computedList]}
        pagination={{ pageSize: 10 }}
        columns={columns}
        locale={{
          emptyText: (
            <Empty description='Chưa có dữ liệu hoặc không tìm thấy' />
          ),
        }}
      />
    </div>
  )
}

export default UserWithdrawScreen

