import { Button, Empty, Form, Input, message, Space, Spin, Switch, Tabs } from 'antd'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import BaseAPI from 'utils/BaseAPI'
import FormCreate from './Components/FormCreate'
import ResultList from './Components/ResultList'
import styles from './style.module.scss'
const { TabPane } = Tabs

const fetchConfigData = async () => {
  return BaseAPI.get('/config')
}

const postUpdateConfig = async (data) => {
  return BaseAPI.patch('/config/:helloworld', data)
}

const ResultScreen = () => {
  const queryClient = useQueryClient()
  const query = useQuery('config', fetchConfigData)
  const { data } = query?.data || {}

  const mutation = useMutation(postUpdateConfig, {
    onSuccess: () => {
      message.success('Cập nhật thông tin hoàn tất!')
      queryClient.invalidateQueries('config')
    }
  })

  const onSaveConfig = (formValues) => {
    Object.keys(formValues).forEach(key => {
      mutation.mutate({
        name: key,
        value: formValues[key]
      }, formValues[key])
    })
  }

  if (query.isLoading) {
    return <Spin />
  }

  if (query.error) {
    return <Empty />
  }

  // const defaultValue = data.docs.find(it=> it.name === 'message')?.value
  return (
    <main>
      <div style={{display:'flex',justifyContent:"space-between",alignItems:"center"}}>
        <h1 style={{ fontSize: 24 }}>Kết quả xổ số</h1>
        <FormCreate/>
      </div>
      

      <div style={{backgroundColor:'#fff', padding: "0 24px 24px"}}>
      <Tabs>
        <TabPane tab="Keno" key="1">
          <ResultList type="keno"/>
        </TabPane>
        <TabPane tab="Power" key="2">
          <ResultList type="power"/>
        </TabPane>
        <TabPane tab="Mega" key="3">
          <ResultList type="mega"/>
        </TabPane>
      </Tabs>
      </div>
    </main>
  )
}

export default ResultScreen
