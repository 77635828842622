export const ORDER = "order";
export const CANCEL = "cancel";
export const SHIPPING = "shipping";
export const COMPLETE = "complete";

// lottery type
export const KENO = "keno";
export const POWER = "power";
export const MEGA = "mega";
export const MAX_3D = "3d";
export const MAX_4D = "4d";

// history type
export const SHOP = "shop";
export const ONLINE = "online";
export const BUY = "buy";

// user type
export const DONE = "done";
export const SUBMIT = "submit";

export const getTypeLottery = (type) => {
  const objType = {
    [KENO]: "Keno",
    [POWER]: "Power 6/55",
    [MEGA]: "Mega 6/45",
    [MAX_3D]: "Max 3D",
    [MAX_4D]: "Max 4D"
  };
  return objType[type];
};

export const getStatusLottery = (type) => {
  const objStatus = {
    [ORDER]: "Mới",
    [CANCEL]: "Huỷ",
    [SHIPPING]: "Đang vận chuyển",
    [COMPLETE]: "Hoàn thành",
    [DONE]: "ĐÃ CHUYỂN KHOẢN",
    [SUBMIT]: "ĐANG CHỜ"
  };
  return objStatus[type];
};

export const getColorStatusLottery = (type) => {
  const objColorStatus = {
    [ORDER]: "#f50",
    [CANCEL]: "red",
    [SHIPPING]: "green",
    [COMPLETE]: "#2db7f5",
    [DONE]: "#1dd1a1",
    [SUBMIT]: "#1890ff"
  };
  return objColorStatus[type];
};
