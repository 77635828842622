import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoginScreen from "../../pages/LoginScreen";
import DashboardLayout from "../Layouts/DashboardLayout";
import { useMemo } from "react";
import routes from "config/routes";

const App = () => {
  const {user, token} = useSelector((state) => state.user);

  const router = useMemo(() => {
    if (!token) return []
    
    if (user?.role === 'admin') {
      return routes
    }
    return routes.filter(it=> !it.permission || it.permission.some(pms => user.permission.includes(pms)))
  }, [user,token])
  
  const renderRoutes = useMemo(() => {
    return router.map(it => <Route key={it.path} component={it.component} exact path={it.path}/>)
   }, [router])

  return (
    <main>
      <BrowserRouter>
        <Switch>
          {token ? (
            <DashboardLayout>
              {renderRoutes}
            </DashboardLayout>
          ) : (
            <>
              <Route exact path="/" component={LoginScreen} />
              <Route render={() => <Redirect to="/" />} />
            </>
          )}
        </Switch>
      </BrowserRouter>
    </main>
  );
};

export default App;
