import { Button, Card, Col, DatePicker, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BaseAPI from "utils/BaseAPI";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TicketAnalyze = () => {
  const [range, setRange] = useState([
    moment().subtract(1, "day"),
    moment().add(1, "day"),
  ]);
  const [data, setData] = useState({});

  const onFetchAnalyze = async () => {
    const res = await BaseAPI.post("/ticket/analyze", {
      from: range[0],
      to: range[1],
    });

    setData(res.data);
  };

  useEffect(() => {
    onFetchAnalyze();
  }, [range]);

  return (
    <>
      <Row>
        <Col span={12}>
          <h2>Thống kê bán hàng</h2>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <DatePicker.RangePicker
            style={{ marginRight: 12 }}
            value={range}
            onChange={setRange}
          />
          <ExcelFile
            filename={`XSKT_${range[0].format("DD_MM_YYYY")}_${range[1].format(
              "DD_MM_YYYY"
            )}`}
            element={<Button>Xuất Excel</Button>}
          >
            <ExcelSheet data={data.cancelTickets || []} name="Vé Huỷ">
              <ExcelColumn label="Dãy số" value="ticketNumber" />
              <ExcelColumn label="Số lượng" value="amount" />
              <ExcelColumn label="Chọn đài" value="chanel" />
              <ExcelColumn
                label="Vé ngày"
                value={(value) => moment(value.date).format("LLLL")}
              />
            </ExcelSheet>
            <ExcelSheet data={data.orders || []} name="Vé Bán">
              <ExcelColumn label="Chọn đài" value={(value) => value.ticket.chanel} />
              <ExcelColumn
                label="Khách hàng"
                value={(value) => value.user.name}
              />
              <ExcelColumn
                label="Số điện thoại"
                value={(value) => value.user.phoneNumber}
              />
              <ExcelColumn label="Số lượng" value="amount" />
              <ExcelColumn
                label="Số"
                value={(value) => value.ticket.ticketNumber}
              />
              <ExcelColumn
                label="Ngày bán"
                value={(value) => moment(value.createdAt).format("LLLL")}
              />
            </ExcelSheet>
            <ExcelSheet data={data.prebooks || []} name="Vé đặt trước">
              <ExcelColumn label="Số chọn" value="ticket" />
              <ExcelColumn label="Số lượng" value="amount" />
              <ExcelColumn label="Khách hàng" value={(value) => value.user.name} />
              <ExcelColumn label="Số điện thoại" value={(value) => value.user.phoneNumber} />
              <ExcelColumn
                label="Vé ngày"
                value={(value) => moment(value.date).format("LLLL")}
              />
            </ExcelSheet>
          </ExcelFile>
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col span={6}>
          <Card title="Tổng số vé">
            <h1>{new Intl.NumberFormat().format(data.sale + data.cancel)}</h1>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Số vé bán">
            <h1>{data.sale}</h1>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Số vé huỷ">
            <h1>{data.cancel}</h1>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Tổng doanh thu">
            <h1>{new Intl.NumberFormat().format(data.cash)}đ</h1>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TicketAnalyze;
