import { Button, notification, Table } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import BaseAPI from 'utils/BaseAPI'

const fetchTickets = (page) => {
  return BaseAPI.post(`/order/all?page=${page}`)
}

const SaleTicket = () => {
  const [page, setPage] = useState(1)
  const [isPrinting, setIsPrinting] = useState({})
  const queryClient = useQueryClient();
  const query = useQuery(['saletickets', page], () => fetchTickets(page))
  
  const { data } = query?.data || {}

  const onPrint = async ({ _id }) => {
      try{
        setIsPrinting(state => ({...state,[_id]: true}))

        await BaseAPI.post(`/ticket/print`, {
          id: _id
        })

        queryClient.invalidateQueries(['saletickets', page])

        notification.success({
          message:"Hoàn tất",
          description:`Đã in vé hoàn tất!`
      })
        
      } catch(e){
        notification.error({
          message:"Lỗi",
          description:"Vui lòng thử lại sau ít phút!"
      })
      } finally{
        setIsPrinting(state => ({...state,[_id]: false}))
      }

      
  }

  
  const columns = [
    {
      title:"Khách Hàng",
      dataIndex: 'user.phoneNumber',
      key:'user.phoneNumber',
      render: (cell, row) => {
        return row.user?.phoneNumber
      }
    },
    {
      title:"Đài",
      dataIndex: 'user.channel',
      key:'user.channel',
      render: (cell, row) => {
        return row.ticket?.chanel
      }
    },
    {
      title:"Vé",
      dataIndex: 'user.phoneNumber',
      key:'user.phoneNumber',
      render: (cell, row) => {
        return <div>
          Vé số: {row.ticket?.ticketNumber} <br />
          Vé Ngày: {row.ticket?.ngay}
        </div>
      }
    },
    {
      title:"Ngày mua",
      dataIndex: 'createdAt',
      key:'createdAt',
      render: (cell, row) => {
        return moment(cell).format("L")
      }
    },
    {
      title:"Thao tác",
      dataIndex: 'action',
      key:'action',
      render: (cell, row) => {
        if(row.isPrint) return "Đã in vé"
        
        return <div onClick={() => onPrint(row)}>
          <Button loading={isPrinting[row._id]} type={!row.isPrint ? "primary" : "danger"}>
              {!row.isPrint ? 'In Vé' : 'Đã in vé, in lại'}
          </Button>
        </div>
      }
    }
  ]

  return (
    <Table columns={columns} loading={query.isLoading} dataSource={data?.docs || []} pagination={{
      current: page,
      pageSize: 10,
      total: query?.data?.total,
      onChange: setPage,
      showSizeChanger:false
    }}/>
  )
}

export default SaleTicket