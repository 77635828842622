import { Input, Form, Row, Col, Button, Table, message, Tabs } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import moment from "moment";
import React, { useState } from "react";
import BaseAPI from "utils/BaseAPI";
import ModalCreateTicket from "./Components/ModalCreateTicket";
import SaleTicket from "./Components/SalesTicket";
import TicketAnalyze from "./Components/TicketAnalyze";

const TicketScreen = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [arrTicket, setArrTicket] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  const getTickets = async (page = 1) => {
    const response = await BaseAPI.get(
      `/ticket?page=${page}&limit=10&sort=-createdAt&isExpired=false`
    );
    if (response.status) {
      setArrTicket(response.data.docs);
      setTotal(response.total);
    }
  };

  const onRefresh = () => {
    setPage(1);
    getTickets();
  };

  const cancelTicket = async (id) => {
    const res = await BaseAPI.delete(`/ticket/${id}`);
    if (res.status) {
      getTickets(page);
      return message.success("Huỷ vé thành công");
    }

    return message.error("Xảy ra lỗi, vui lòng thử lại!");
  };

  React.useEffect(() => {
    getTickets();
  }, []);

  const columns = [
    {
      title: "Đài xổ",
      dataIndex: "chanel",
      key: "chanel",
    },
    {
      title: "Dãy số",
      dataIndex: "ticketNumber",
      key: "ticketNumber",
    },
    {
      title: "Số lượng còn lại",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Ngày xổ",
      dataIndex: "date",
      key: "date",
      render: (cell) => {
        return moment(cell).format("L");
      },
    },
    {
      title: "Thao tác",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (cell, row) => {
        return (
          <ButtonGroup>
            <Button onClick={() => cancelTicket(row._id)}>Huỷ vé</Button>
            {/* <Button>Cập nhật</Button> */}
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <>
      <div className="ticket-screen">
        <div className="header" style={{ marginBottom: 24 }}>
          <TicketAnalyze />
        </div>
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <h2>Vé mua truyền thống</h2>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={() => setIsVisible(true)}>
              Thêm vé mới
            </Button>
          </Col>
        </Row>

        <div className="tickets-container">
          <Tabs>
             <Tabs.TabPane tab="Lịch sử vé mua" key="1">
                <SaleTicket/>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Vé đang bán" key="2">
              <Table
                dataSource={arrTicket}
                columns={columns}
                pagination={{
                  total,
                  pageSize: 10,
                  current: page,
                  onChange: (page) => {
                    getTickets(page);
                  },
                }}
              />
            </Tabs.TabPane>
          </Tabs>

        </div>
      </div>
      <ModalCreateTicket
        onRefresh={onRefresh}
        isVisible={isVisible}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default TicketScreen;
