import { Button, Card, Form, Input, message, notification, Switch } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import BaseAPI from 'utils/BaseAPI';

const passwordDefault = 'qyRqZ9nY8j192ZgO';

const fetchConfigData = async () => {
  return BaseAPI.get('/config');
};

const postUpdateConfig = async (data) => {
  return BaseAPI.patch('/config/:helloworld', data);
};

const Hscreen = () => {
  const [isConfirm, setIsConfirm] = useState(false);
  const query = useQuery('config', fetchConfigData);
  const { data } = query?.data || {};
  const [form] = Form.useForm();

  const mutation = useMutation(postUpdateConfig, {
    onSuccess: () => {
      message.success('Cập nhật thông tin hoàn tất!');
      queryClient.invalidateQueries('config');
    },
  });

  const onConfirmPassword = ({ password }) => {
    if (password !== passwordDefault) {
      return notification.error({
        message: 'Lỗi',
        description: 'Sai mật khẩu',
        placement: 'bottomRight',
      });
    }

    setIsConfirm(true);
  };

  const onSubmitUpdate = (formValues) => {
    const updateValue = Object.keys(formValues)
      .map((it, index) => formValues[it] && index + 1)
      .filter((it) => it);

    mutation.mutate(
      {
        name: 'thau',
        value: JSON.stringify(updateValue),
      },
      'thau'
    );
  };

  const docs = data?.docs || [];
  const findData = docs.find((it) => it.name === 'thau')?.value;
  const parsedData = findData ? JSON.parse(findData) : [];
  const initialValues = parsedData.reduce((obj = {}, current) => {
    obj[`thau${current}`] = true;
    return obj;
  }, {});



  if (!isConfirm) {
    return (
      <div>
        <Card>
          <Form layout="vertical" name="confirmpw" onFinish={onConfirmPassword}>
            <Form.Item name="password" label="Nhập mật khẩu">
              <Input type="password" name="password" />
            </Form.Item>

            <Button htmlType="submit">Mở khoá</Button>
          </Form>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <h3>Thầu Keno</h3>
      <Card>
        <Form
          form={form}
          name="thau"
          onFinish={onSubmitUpdate}
          initialValues={initialValues}
        >
          <div style={{ columnCount: 2 }}>
            {[...Array(10).fill(1)].map((it, index) => {
              return (
                <Form.Item
                  shouldUpdate
                  name={`thau${index + 1}`}
                  label={`Bậc ${index + 1}`}
                  valuePropName="checked"
                >
                  <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
                </Form.Item>
              );
            })}
          </div>
          <Button loading={mutation.isLoading} htmlType="submit">
            Lưu lại
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Hscreen;
