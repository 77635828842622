import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select
} from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import BaseAPI from "../../../utils/BaseAPI";

const UserNewScreen = () => {
  const [form] = Form.useForm();

  const { state } = useLocation();
  const [createdUser, setCreatedUser] = React.useState(state);

  const onFinish = async (formValues) => {
    try {
      if (!formValues.email) {
        delete formValues.email;
      }
      await BaseAPI.post("user", {...formValues, cardID: '0000000000'});
      notification.success({
        title: "Thành công",
        description: "Tạo tài khoản thành công",
        placement: "bottomRight"
      });
      form.resetFields();
    } catch (e) {
      notification.error({
        title: "Lỗi",
        description: "Xảy ra lỗi hoặc tài khoản đã tồn tại, mã lỗi -5001",
        placement: "bottomRight"
      });
    }
  };

  const onFinishFailed = () => {
    notification.error({
      title: "Lỗi",
      description: "Vui lòng kiểm tra thông tin đã nhập!",
      placement: "bottomRight"
    });
  };

  const options = [
    { label: 'Nạp Tiền', value: 'deposit' },
    { label: 'Rút tiền', value: 'withdraw' },
    { label: 'Danh sách tài khoản', value: 'userList' },
    { label: 'Khoá tài khoản', value: 'banUser' },
    { label: 'Thống kê bán hàng', value: 'saleBoard' },
    { label: 'Lịch sử giao dịch', value: 'transactionList' },
    { label: 'Lịch sử rút tiền', value: 'withdrawList' },
    { label: 'Lịch sử vé bán', value: 'saleList' },
    { label: 'Lịch sử vé bán (Web)', value: 'saleListWeb' },
    { label: 'Vé truyền thống', value: 'ticket' },
    { label: 'App bán hàng', value: 'app' },
  ]

  return (
    <div className="user-new-container">
      <div className="flex justify-between align-center">
        <h1>Tạo tài khoản mới</h1>
        <Button onClick={form.submit} type="primary">
          Thêm mới
        </Button>
      </div>
      <Card className="mt2">
        <Form
          form={form}
          name="newCustomerForm"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={createdUser}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="Số điện thoại"
                rules={[
                  { required: true, message: "Vui lòng nhập số điện thoại" }
                ]}
              >
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Tên"
                rules={[
                  { required: true, message: "Vui lòng điền tên" }
                ]}
              >
                <Input placeholder="Nhập tên" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}
              >
                <Input.Password placeholder="Nhập mật khẩu" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="passwordConfirm"
                label="Xác nhận mật khẩu"
                rules={[
                  {
                    required: true,
                    message: "Xác nhận lại mật khẩu không chính xác"
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Xác nhận mật khẩu" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item name="balance" label="Số dư (Không bắt buộc)">
                <InputNumber style={{ width: '100%' }}
                  formatter={(value) =>
                  new Intl.NumberFormat().format(value)}
                  parser={(value) => value.split(',').join('')} />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item 
                name="email" 
                label="Email liên hệ (Không bắt buộc)"  
                rules={[
                    {
                      type: 'email',
                      message: 'Địa chỉ email không hợp lệ',
                    },
                  ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="role" label="Phân quyền" rules={[{required: true}]}>
                <Select placeholder="Chọn loại tài khoản">
                  <Select.Option value="user">
                    Khách hàng
                  </Select.Option>
                  <Select.Option value="staff">
                    Nhân viên bán hàng
                  </Select.Option>
                  <Select.Option value="agency">Cửa hàng</Select.Option>
                  <Select.Option value="admin">Admin</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="permission" label="Phân Quyền">
                <Checkbox.Group options={options} />
                </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default UserNewScreen;
