import { Form, Button, Input, Modal, Select, notification, DatePicker } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import BaseAPI from 'utils/BaseAPI'
import styles from '../style.module.scss'
const FormCreate = () => {
    const queryClient = useQueryClient()
    const [isOpen, setIsOpen] = useState(false)
    const [type, setType] = useState("keno")
    const [form] = Form.useForm()
    const [nums, setNums] = useState([])
    const onToggleModal = () => {
        setIsOpen(!isOpen)
    }

    const onSubmit = async (values) => {
        let data;

        try{
            if (type === "keno") {
                data = {
                    ...values,
                    result: nums,
                    chan: nums.filter(it => it % 2 === 0).length,
                    le: nums.filter(it => it % 2 !== 0).length,
                    lon: nums.filter(it => it > 40).length,
                    nho: nums.filter(it => it <= 40).length
                }
            } else if (type === "mega") {
                data = { ...values, result: nums }
            } else if (type === "power") {
                data = { ...values, result: nums }
            }
    
            delete data.data;

            const {data:res} = await BaseAPI.post("/admin/result", {type, data})
            

            if(!res.status){
                throw res.message
            }

            form.resetFields();
            setNums([])
            
            notification.success({
                message:"Thêm thành công!",
                description:"Đã thêm kết quả thành công!"
            })

            onToggleModal()
            queryClient.invalidateQueries(`result${type}`)
            
        } catch(e){
            notification.error({
                message: "Lỗi",
                description: e
            })
        }

    }

    const onFieldsChange = (values) => {
        if (values[0]?.name[0] === "data") {
            try {
                const value = values[0]?.value?.split(' ')

                const kyDate = value[1].split('#')[1]
                const ky = kyDate.slice(0, kyDate.length - 10)
                const date = kyDate.slice(kyDate.length - 10, kyDate.length)

                const dSplit = date.split('-')
                console.log(`🐳 -> onFieldsChange -> dSplit`, dSplit)
                const timeSplit = value[2].split('\n')[0].split(':')
                console.log(`🐳 -> onFieldsChange -> timeSplit`, timeSplit)

                const time = new Date(dSplit[2], dSplit[1] - 1, dSplit[0] - 1, timeSplit[0], timeSplit[1] | '0')

                const nums = values[0]?.value?.split(value[2].split('\n')[0])[1].split("\n").filter(it => it)
                setNums(nums)

                form.setFieldsValue({
                    ky, date: moment(time),
                    result: nums.join(" ")
                })

            } catch (e) {
                form.resetFields()
                setNums([])
            }
        }

        if (values[0]?.name[0] === "result") {
            setNums(values[0]?.value?.split(' ') || [])
        }

        if (values[0]?.name[0] === "type") {
            setType(values[0]?.value)
        }

    }

    useEffect(()=> {
        form.resetFields()
        setNums([])
        form.setFieldsValue({
            type
        })
    },[type])

    return (
        <div>
            <Button type="primary" onClick={onToggleModal}>Nhập Kết Quả</Button>
            <Modal wrapClassName={styles.module} title="Thêm thông tin mới" visible={isOpen} onOk={form.submit} onCancel={onToggleModal}>
                <Form onFinish={onSubmit} onFieldsChange={onFieldsChange} layout="vertical" name='form' form={form}>
                    <Form.Item name="type" label="Loại vé" rules={[{ required: true }]} required>
                        <Select>
                            <Select.Option value="keno">Keno</Select.Option>
                            <Select.Option value="mega">Mega</Select.Option>
                            <Select.Option value="power">Power</Select.Option>
                        </Select>
                    </Form.Item>

                    {type && type === "keno" && (
                        <Form.Item name="data" label="Data">
                            <Input.TextArea name="data" />
                        </Form.Item>
                    )}

                    <Form.Item name="ky" label="Kỳ" required rules={[{ required: true }]}>
                        <Input name="ky" />
                    </Form.Item>
                    <Form.Item name="date" label="Thời gian">
                        {/* <Input type="datetime-local" name="date" /> */}
                        <DatePicker name="date" showTime/>
                    </Form.Item>
                    <Form.Item name="result" label="Kết quả (Cách nhau bởi dấu cách)" rules={[{ required: true }]} required>
                        <Input name="ky" />
                    </Form.Item>


                    {type && type !== "keno" && (
                        <Form.Item name="jackpot" label="Giải Jackpot" required rules={[{ required: true }]}>
                            <Input type="number" name="jackpot" />
                        </Form.Item>
                    )}

                    {type === "power" && (
                        <Form.Item name="jackpot2" label="Giải Jackpot 2" required rules={[{ required: true }]}>
                            <Input type="number" name="jackpot2" />
                        </Form.Item>
                    )}


                    {
                        nums?.length > 0 && (
                            <>
                                <div>Bộ kết quả</div>
                                <ul>
                                    {nums.map((it,index) => <li className={index === nums.length -1 && type==="power" && "last-num"}>{it}</li>)}
                                </ul>
                            </>
                        )
                    }

                    {nums.length > 0 && type === "keno" && (
                        <div style={{marginTop: 24}}>
                            <div>Chẵn: {nums.filter(it => it % 2 !== 0).length}</div>
                            <div>Lẻ: {nums.filter(it => it % 2 === 0).length}</div>
                            <div>Lớn: {nums.filter(it => it > 40).length}</div>
                            <div>Lẻ: {nums.filter(it => it <= 40).length}</div>
                        </div>
                    )}
                </Form>
            </Modal>
        </div>
    )
}

export default FormCreate