import { Button, Empty, Input, Table, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import BaseAPI from 'utils/BaseAPI';
import './style.scss';

const UserTransactionScreen = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const LIMIT = 10;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LIMIT,
    total: 1,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [idItem, setIdItem] = useState('');
  const [firstRender, setFirstRender] = useState(false);
  const fetchData = async ({ page = 1 }) => {
    if(list.length > 0) return false;
    setIsLoading(true);

    try {
      const response = await BaseAPI.get(`/transaction/all?limit=${1000000000000}`);
      if (response.status) {
        const { data, total } = response;
        console.log(response.data.docs);
        setList(data.docs);
        setPagination({
          total,
          current: page,
          pageSize: 10
        });
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const handleTableChange = (params) => {
    setPagination({ ...params });
    fetchData({ status: null, page: params.current });
  };

  const onChangeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  };
  const computedList = useMemo(() => {
    if (!keyword) {
      return list;
    }
    return list.filter((it) => !!it.customer && it.customer.phoneNumber.indexOf(keyword) >= 0);
  }, [keyword, list]);
  const handleDetail = (id) => () => {
    setIdItem(id);
    setIsVisible(true);
  };
  const renderContentModal = useMemo(() => {
    if (!firstRender) {
      setFirstRender(!firstRender);
      return;
    }
    if (!idItem) return;
    const findItem = list.find((it) => it._id === idItem);
    return (
      <div className='info-lottery'>
        <div className='info-lottery__user'>
          <div className='item'>
            <p>
              Name:{' '}
              <span>
                {findItem.customer
                  ? get(findItem.customer, 'name', 'name default')
                  : 'name default'}
              </span>
            </p>
          </div>
          <div className='item'>
            <p>
              Sđt:
              <span>
                {findItem.customer
                  ? get(findItem.customer, 'phoneNumber', 'phone default')
                  : 'phone default'}
              </span>
            </p>
          </div>
          <div className='item'>
            <p>
              Trạng thái:{' '}
              <span>{get(findItem, 'method', '') === 'add' ? 'Nạp tiền' : 'Rút tiền'}</span>
            </p>
          </div>
          <div className='item'>
            <p>
              Lí do: <span>{get(findItem, 'reason', '')}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }, [firstRender, idItem, list]);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'customer',
      key: 'name',
      render: (customer) => get(customer, 'name', 'name default'),
    },
    {
      title: 'SĐT',
      dataIndex: 'customer',
      key: 'phoneNumber',
      render: (customer) => get(customer, 'phoneNumber', 'phone default'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'method',
      key: 'active',
      render: (method) =>
        method === 'add' ? <Tag color='#2db7f5'>NẠP</Tag> : <Tag color='red'>RÚT TIỀN</Tag>,
    },
    {
      title: 'Lí do',
      dataIndex: 'reason',
      render: (reason) => reason,
    },
    {
      title: 'Ngày',
      dataIndex: 'createdAt',
      render: (date) => <p>{moment(date).subtract(7, 'hours').locale('vi').format('LL')}</p>,
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      align: 'center',
      render: (id) => (
        <Button type='link' onClick={handleDetail(id)}>
          Chi tiết giao dịch
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  return (
    <div>
      <div className='keyword-row search flex'>
        <h1>Lịch sử giao dịch</h1>
        <div className='search'>
          <Input.Search placeholder='Nhập số điện thoại ' onChange={onChangeKeyword} />
        </div>
      </div>
      <Table
        filterSearch
        loading={isLoading}
        dataSource={[...computedList]}
        pagination={pagination}
        columns={columns}
        onChange={handleTableChange}
        locale={{
          emptyText: <Empty description='Chưa có dữ liệu hoặc không tìm thấy' />,
        }}
      />
      <Modal
        key={idItem}
        visible={isVisible}
        title='Chi tiết giao dịch'
        onCancel={() => setIsVisible(false)}
        onOk={() => setIsVisible(false)}>
        {renderContentModal ? renderContentModal : null}
      </Modal>
    </div>
  );
};

export default UserTransactionScreen;
