import { Button, Empty, Form, Input, message, Space, Spin, Switch } from 'antd'
import React from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import BaseAPI from 'utils/BaseAPI'


const fetchConfigData = async () => {
  return BaseAPI.get('/config') 
}

const postUpdateConfig = async (data) => {
  return BaseAPI.patch('/config/:helloworld', data)
}

const ConfigScreen = () => {
  const queryClient = useQueryClient()
  const query = useQuery('config', fetchConfigData)
  const { data } = query?.data || {};

  

  const mutation = useMutation(postUpdateConfig, {
    onSuccess: () => {
        message.success("Cập nhật thông tin hoàn tất!")
        queryClient.invalidateQueries("config")
    }
  })

  const onSaveConfig = (formValues) => {
    Object.keys(formValues).forEach(key => {
      mutation.mutate({
        name: key,
        value: formValues[key]
      }, formValues[key])
    })
  }


  if(query.isLoading){
    return <Spin/>
  }

  if(query.error){
    return <Empty />
  }

  // const defaultValue = data.docs.find(it=> it.name === 'message')?.value
  const initialValues =  data.docs.slice().reduce((final ={}, current) => {
     final[current.name] = current.value
     return final;
  }, {})
  

  return (
    <Form  layout="vertical" name='form-update' id="form-update" onFinish={onSaveConfig} initialValues={{
      ...initialValues,
      isKenoOn: JSON.parse(initialValues.isKenoOn),
      isSystemOn: JSON.parse(initialValues.isSystemOn),
    }}>
      <h1 style={{fontSize: 24}}>Cấu hình hệ thống</h1>

      <Form.Item name="isKenoOn" label="Dịch Vụ Keno" valuePropName='checked'>
        <Switch checkedChildren="Bật" unCheckedChildren="Tắt"/>
      </Form.Item>
      <Form.Item name="isSystemOn" label="Toàn Bộ Hệ Thống" valuePropName='checked'>
        <Switch checkedChildren="Bật" unCheckedChildren="Tắt"/>
      </Form.Item>
      <Form.Item name="isCheat" label="Cheat" valuePropName='checked'>
        <Switch checkedChildren="Bật" unCheckedChildren="Tắt"/>
      </Form.Item>


      {/* <Form.Item name="isTake3" label="Bậc 3 trở xuống" valuePropName='checked'>
        <Switch checkedChildren="Bật" unCheckedChildren="Tắt"/>
      </Form.Item>

      <Form.Item name="isTake4" label="Bậc 4 trở xuống" valuePropName='checked'>
        <Switch checkedChildren="Bật" unCheckedChildren="Tắt"/>
      </Form.Item>

      <Form.Item name="isTake5" label="Bậc 5 trở xuống" valuePropName='checked'>
        <Switch checkedChildren="Bật" unCheckedChildren="Tắt"/>
      </Form.Item>

      <Form.Item name="isTake6" label="Bậc 6 trở xuống" valuePropName='checked'>
        <Switch checkedChildren="Bật" unCheckedChildren="Tắt"/>
      </Form.Item> */}

      


      <Form.Item name={"message"} label="Thông tin in phiếu truyền thống">
        <Input.TextArea disabled={mutation.isLoading} title='Thông tin in phiếu truyền thống' rows={10} placeholder="Thông tin in trên phiếu"/>
      </Form.Item>

      <Space direction="vertical" size={12}/>
      <div>
        <Button loading={mutation.isLoading} htmlType="submit" type="primary">Lưu lại</Button>
      </div>
    </Form>
  )
}

export default ConfigScreen