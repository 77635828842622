import {
  Button,
  Empty,
  Form,
  Input,
  Modal,
  notification,
  Table,
  Tag,
} from 'antd';
import { get, toLower } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import BaseAPI from '../../../utils/BaseAPI';

import './style.scss';

const AgencyScreen = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await BaseAPI.get('/agency?limit=100000');

      if (response.status) {
        setList(response.data.docs);
      }
    } catch (e) {
      //
    }
    setIsLoading(false);
  };

  const computedList = useMemo(() => {
    if (!keyword) {
      return list;
    }

    const lowerKeyword = toLower(keyword);

    return list.filter((it) => toLower(it.name).indexOf(lowerKeyword) >= 0);
  }, [keyword, list]);

  const onChangeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  };

  const handleCreateAgency = async (formData) => {
    try {
      const response = await BaseAPI.post('/agency', formData);

      const newAgency = get(response, 'data.doc');
      if (newAgency) {
        notification.success({
          title: 'Hoàn tất',
          description: 'Đã thêm CD-KEY thành công',
          placement: 'bottomRight',
        });
        setList([newAgency, ...list.slice()]);
      }

      form.resetFields();
      setIsCreate(false);
    } catch (e) {
      notification.error({
        title: 'Lỗi',
        description: 'Vui lòng thử lại sau ít phút',
        placement: 'bottomRight',
      });
    }
  };

  const handleDeleteAgency = (id) => () => {
    Modal.confirm({
      title: 'Xác nhận',
      content: 'Bạn có chắc muốn xoá CD-KEY này ?',
      type: 'warning',
      onOk: async () => {
        // Handling Delete and refresh
        try {
          await BaseAPI.delete(`agency/${id}`);
          notification.success({
            title: 'Hoàn tất',
            description: 'Đã xoá CD-KEY thành công',
            placement: 'bottomRight',
          });
          setList(list.slice().filter((it) => it._id !== id));
        } catch (e) {
          notification.error({
            title: 'Lỗi',
            description: 'Vui lòng thử lại sau ít phút',
            placement: 'bottomRight',
          });
        }
      },
    });
  };

  const onCopy = (copyContent) => () => {
    window.navigator.clipboard.writeText(copyContent);
    notification.success({
      title: 'Đã Copy',
      description: 'Đã COPY CD key vào bộ nhớ',
      placement: 'bottomRight',
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    {
      title: 'Định danh',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CD-KEY',
      dataIndex: 'cdKey',
      key: 'cdKey',
      render: (cd) => <div onClick={onCopy(cd)}>{cd}</div>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'deviceID',
      key: 'deviceID',
      render: (isActive) =>
        isActive ? (
          <Tag color="#2db7f5">Đã kết nối</Tag>
        ) : (
          <Tag color="red">Chưa sử dụng</Tag>
        ),
    },
    {
      title: 'Thao tác',
      dataIndex: '_id',
      align: 'right',
      render: (id) => (
        <>
          <Button type="danger" onClick={handleDeleteAgency(id)}>
            Xoá
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="keyword-row">
        <h1>Danh sách CD-KEY</h1>
        <div className="search flex">
          <Button
            type="primary"
            to="/"
            style={{ marginRight: 12 }}
            onClick={() => setIsCreate(true)}>
            Thêm mới
          </Button>
          <Input.Search
            placeholder="Nhập từ khoá cần tìm"
            onChange={onChangeKeyword}
          />
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={[...computedList]}
        pagination={{ pageSize: 10 }}
        columns={columns}
        locale={{
          emptyText: (
            <Empty description="Chưa có dữ liệu hoặc không tìm thấy" />
          ),
        }}
      />

      {/* Modal */}
      <Modal
        visible={isCreate}
        onCancel={() => setIsCreate(false)}
        onOk={form.submit}
        title="Tạo CD-Key mới">
        <Form name="agencyCreate" form={form} onFinish={handleCreateAgency}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: 'Vui lòng nhập tên định danh' },
            ]}>
            <Input placeholder="Nhập tên định danh" allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AgencyScreen;
