import { Dropdown, Layout, Menu } from 'antd'
import React, { useMemo } from 'react'
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  CaretDownFilled,
  HomeOutlined,
} from '@ant-design/icons'

import './style.scss'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { handleUserLogout } from '../../store/reducers/userReducer'
import sidebar from 'config/sidebar'

const { SubMenu } = Menu
const { Header, Content, Sider } = Layout

const DashboardLayout = ({ children }) => {
  const location = useLocation()

  const userRedux = useSelector((state) => state.user)

  const userName = get(userRedux, 'user.name')

  const dispatch = useDispatch()

  const onLogout = React.useCallback(() => {
    dispatch(handleUserLogout)
  }, [dispatch])

  const menu = (
    <Menu>
      <Menu.Item onClick={onLogout}>Đăng xuất</Menu.Item>
    </Menu>
  )

  const renderSideBar = () => {

    const { user } = userRedux

    if (user.role !== "admin") {
      sidebar.map(it => {
        if(it.subMenu)
        it.subMenu = it.subMenu.filter(item => !item.permission || item.permission.some(pms => user.permission.includes(pms)))
        return it;
      }).filter(it=> !it.permission || it.permission.some(pms => user.permission.includes(pms)))
    }

    return sidebar.map((bar) => {
      if (bar.subMenu) {
        // handle with sub
        return (
          <SubMenu key={bar.key} icon={bar.icon} title={bar.title}>
            {bar.subMenu.map((sub) => (
              <Menu.Item key={sub.key}>
                <Link to={sub.key}>{sub.title}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        )
      }

      return (
        <Menu.Item key={bar.key} icon={bar.icon}>
          <Link to={bar.key}>{bar.title}</Link>
        </Menu.Item>
      )
    })
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className='header'>
        <div className='logo'>
          <Link to='/' style={{ color: 'white' }}>
            <img src='/logo.png' alt='' style={{ width: 40 }} />
          </Link>
        </div>
        <Dropdown overlay={menu}>
          <div className='flex align-center' style={{ color: 'white' }}>
            Xin Chào, {userName}
            <CaretDownFilled style={{ color: 'white', marginLeft: 8 }} />
          </div>
        </Dropdown>
      </Header>
      <Layout>
        <Sider width={200} className='site-layout-background'>
          <Menu
            mode='inline'
            // defaultSelectedKeys={['1']}
            selectedKeys={location.pathname}
            defaultOpenKeys={sidebar.map(it=> it.key)}
            style={{ height: '100%', borderRight: 0 }}
          >
            {renderSideBar()}
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className='site-layout-background'
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
