import { DatePicker, Form, Input, message, notification, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React from "react";
import { useState } from "react";
import BaseAPI from "utils/BaseAPI";

const list = [
  ["Phú Yên", "Thừa Thiên Huế", "Đồng Tháp", "Hồ Chí Minh", "Cà Mau"], //t2
  ["Đắc Lắc", "Quảng Nam", "Bạc Liêu", "Bến Tre", "Vũng Tàu"], //t3
  ["Đà Nẵng", "Khánh Hoà", "Cần Thơ", "Đồng Nai", "Sóc Trăng"], //t4
  [
    "Bình Định",
    "Quảng Bình",
    "Quảng Trị",
    "An Giang",
    "Bình Thuận",
    "Tây Ninh",
  ], //t5
  ["Gia Lai", "Ninh Thuận", "Bình Dương", "Vĩnh Long", "Trà Vinh"], //t6
  [
    "Đắc Nông",
    "Quảng Ngãi",
    "Đà Nẵng",
    "Bình Phước",
    "Hậu Giang",
    "Long An",
    "Hồ Chí Minh",
  ], //t7
  ["Khánh Hoà", "Kon Tum", "Đà Lạt", "Kiên Giang", "Tiền Giang"],
];

const ModalCreateTicket = ({ isVisible, handleCloseModal, onRefresh }) => {
  const [form] = Form.useForm();
  const [daiso, setDaiSo] = useState([]);

  const onValuesChange = (current, form) => {
    if (current.date) {
      const weekday = current.date.weekday();
      setDaiSo(list[weekday]);
    }
  };

  const onCreateTicket = async (formData) => {
    const date = moment(formData.date);

    date.hour(17);
    date.minute(0);
    date.second(0);

    const res = await BaseAPI.post("/ticket", { ...formData, date });

    if (res.status) {
      handleCloseModal();
      onRefresh();
      return message.success("Tạo bài mới thành công!");
    }

    return message.error("Xảy ra lỗi, vui lòng thử lại!");
  };

  React.useEffect(() => {
    form.setFieldsValue({ channel: null });
  }, [form, daiso]);

  return (
    <Modal
      visible={isVisible}
      onOk={handleCloseModal}
      closable
      onCancel={handleCloseModal}
      title="Tạo vé mới"
    >
      {/* <Form layout="vertical" onValuesChange={onValuesChange} form={form} name="create-ticket-screen" defaultValue={{price: 10000}} onFinish={onCreateTicket}>
                    <Form.Item name="date" label="Ngày xổ">
                        <DatePicker placeholder="Chọn ngày xổ" style={{width:"100%"}}/>
                    </Form.Item>
                    <Form.Item name="chanel" label="Đài xổ">
                        <Select  placeholder="Chọn đài xổ">
                            {daiso.map(it=> <Select.Option key={Math.random()} value={it}>{it}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item name="ticketNumber" label="Dãy số">
                        <Input placeholder="Nhập dãy số"/>
                    </Form.Item>
                    <Form.Item name="amount" label="Số lượng">
                        <Input placeholder="Nhập số lượng"/>
                    </Form.Item>
                    <Form.Item initialValu="10000" name="price" label="Giá tiền">
                        <Input placeholder="Nhập giá tiền"/>
                    </Form.Item>
                </Form> */}
      <div> Vui Lòng Sử Dụng App Quản Lý để thêm vé mới! </div>
    </Modal>
  );
};

export default ModalCreateTicket;
