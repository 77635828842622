import React, { useState, useMemo, useEffect } from 'react';
import { get, toLower } from 'lodash';
import { Table, Tag, Tabs, Input, Select, Space, Button, Modal } from 'antd';
import moment from 'moment';
import { PrinterOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getStatusLottery, getColorStatusLottery } from '../../constant/status-vietlott';
import { formatCurrency } from '../../utils/format-currency';
import './style.scss';

import {
  SHOP,
  ONLINE,
  BUY,
  ORDER,
  CANCEL,
  SHIPPING,
  COMPLETE
} from '../../constant/status-vietlott';

import InfoLottery from './components/InfoLottery';

import BaseAPI from 'utils/BaseAPI';

const { TabPane } = Tabs;
const LIMIT = 10;
const LOTTERY_TYPE = 'share';
const { Option } = Select;

const HistoryScreen = () => {
  const [visible, setVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('shop');
  const [currentData, setCurrentData] = useState({});
  const [keyword, setKeyword] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [valueSelected, setValueSelected] = useState('all');
  const [tabs, setTabs] = useState([
    {
      name: 'Cửa Hàng',
      slug: SHOP
    },
    {
      name: 'Trực Tuyến',
      slug: ONLINE
    },
    {
      name: 'Mua Chung',
      slug: BUY
    }
  ]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LIMIT,
    total: 1
  });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: 'Số Điện Thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'Trạng Thái',
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <>
          <Tag color={getColorStatusLottery(status)}>{getStatusLottery(status)}</Tag>
        </>
      )
    },
    {
      title: 'Giá Tiền',
      dataIndex: 'price',
      key: 'price',
      render: (money) => <p> {formatCurrency(money)}</p>
    },
    {
      title: 'Ngày',
      key: 'date',
      dataIndex: 'date',
      render: (date) => <p>{moment(date).locale('vi').format('LL')}</p>
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (data) => (
        <Space size='middle'>
          <Button
            onClick={() => showModal(data)}
            type='primary'
            shape='round'
            icon={<InfoCircleOutlined />}
            size='small'
          >
            Xem thông tin
          </Button>
        </Space>
      )
    }
  ]);

  const computedList = useMemo(() => {
    if (!keyword && !valueSelected) {
      return dataTable;
    }
    const filterSelected = dataTable.filter((item) => item.status.indexOf(valueSelected) >= 0);

    if (filterSelected.length) {
      if (!keyword) {
        setPagination({ ...pagination, total: filterSelected.length });
        return filterSelected;
      } else {
        const filterKeywordSelected = filterSelected.filter(
          (item) => item.phoneNumber.indexOf(keyword) >= 0
        );
        setPagination({ ...pagination, total: filterKeywordSelected.length });
        return filterKeywordSelected;
      }
    } else {
      if (valueSelected === 'all') {
        if (!keyword) {
          return dataTable;
        }
        const filterKeyword = dataTable.filter((item) => item.phoneNumber.indexOf(keyword) >= 0);
        setPagination({ ...pagination, total: filterKeyword.length });
        return filterKeyword;
      }
    }

    setPagination({ ...pagination, total: filterSelected.length });
    return filterSelected;
  }, [keyword, valueSelected, dataTable]);

  const onChangeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleChangeSelected = (value) => {
    console.log('value', value);
    setValueSelected(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleChangeTabTable = (status, params) => {
    const page = params ? params.current : 1;
    switch (status) {
      case 'shop':
        fetchData({ status: null, isFromWeb: false, lotteryType: null, page });
        setKeyword('');
        setValueSelected('all');
        break;
      case 'online':
        fetchData({ status: null, isFromWeb: true, lotteryType: null, page });
        setKeyword('');
        setValueSelected('all');
        break;
      case 'buy':
        fetchData({
          status: null,
          isFromWeb: null,
          lotteryType: LOTTERY_TYPE,
          page
        });
        setKeyword('');
        setValueSelected('all');
        break;
    }
  };

  useEffect(() => {
    fetchData({ status: null, isFromWeb: false, lotteryType: null, page: 1 });
  }, []);

  const callback = (key) => {
    setCurrentTab(key);
    handleChangeTabTable(key);
  };

  const showModal = (item) => {
    setCurrentData(item);
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const fetchData = async ({ status, isFromWeb, lotteryType, page, limit }) => {
    try {
      let params = {
        limit: LIMIT,
        page,
        isFromWeb,
        lotteryType,
        status,
        sort: '-createdAt'
      };
      setLoadingTable(true);
      const response = await BaseAPI.get(`/lottery/all`, { params });
      setLoadingTable(false);
      if (!response.status) return;
      const { data, total } = response;
      const arrTemp = get(data, 'docs', []).map((item, index) => ({
        key: index,
        phoneNumber: get(item, 'customer.phoneNumber', 'number default'),
        status: get(item, 'status', 'status default'),
        price: get(item, 'totalPrice', 'price default'),
        date: get(item, 'createdAt', 'date default'),
        type: get(item, 'lotteryType', 'type default'),
        data: item
      }));
      setDataTable(arrTemp);
      setPagination({ ...pagination, current: page, total });
    } catch (e) {
      console.log(e);
    }
  };

  const handleTableChange = (params) => {
    handleChangeTabTable(currentTab, params);
    // setPagination({ ...pagination, ...params });
  };

  return (
    <div className='search__row'>
      <div className='row-tabname'>
        <div>
          <Tabs defaultActiveKey={currentTab} onChange={callback}>
            {tabs.map((tab) => (
              <TabPane key={tab.slug} tab={tab.name}></TabPane>
            ))}
          </Tabs>
        </div>
        <div className='row-search'>
          <div>
            <Select
              defaultValue='all'
              onChange={handleChangeSelected}
              style={{ width: 140 }}
              value={valueSelected}
            >
              <Option value='all'>Tất cả</Option>
              <Option value={ORDER}>Mới</Option>
              <Option value={CANCEL}>Hủy</Option>
              <Option value={SHIPPING}>Đang vận chuyển</Option>
              <Option value={COMPLETE}>Hoàn Thành</Option>
            </Select>
          </div>
          <div>
            <Input.Search
              placeholder='Nhập số điện thoại cần tìm'
              onChange={onChangeKeyword}
              value={keyword}
            />
          </div>
        </div>
      </div>
      <Table
        loading={loadingTable}
        pagination={pagination}
        columns={columns}
        dataSource={[...computedList]}
        onChange={handleTableChange}
      />
      <Modal
        title='Thông Tin Vé Số'
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <InfoLottery dataInfo={currentData} />
      </Modal>
    </div>
  );
};

export default HistoryScreen;
