import { Button, Empty, Input, InputNumber, notification, Table, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BaseAPI from '../../../utils/BaseAPI';

import './style.scss';

const UserBlockedScreen = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [amount, setAmount] = useState(0);
  const [keyword, setKeyword] = useState(null);
  const [pNumber, setPNumber] = useState(null);
  const [pAmount, setPAmount] = useState(null);
  const [cashLoading, setCashLoading] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [reason, setReason] = useState(null);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await BaseAPI.get('/user/all?active=false&limit=100000');
      if (response.status) {
        setList(response.data.docs);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const computedList = useMemo(() => {
    if (!keyword) {
      return list;
    }

    return list.filter(
      (it) => it.phoneNumber.indexOf(keyword) >= 0 || it.name.indexOf(keyword) >= 0,
    );
  }, [keyword, list]);

  const onChangeKeyword = (e) => {
    console.log('here');
    const { value } = e.target;
    setKeyword(value);
  };
  const onLockUser = useCallback(
    (id, user) => () => {
      Modal.confirm({
        title: 'Xác nhận',
        content: 'Bạn có chắc muốn khoá tài khoản này?',
        onOk: async () => {
          const response = await BaseAPI.put(`/user/${id}`, {
            ...user,
            active: true,
          });

          if (response) {
            setList(list.filter((it) => it._id !== id));
          }
        },
      });
    },
    [list],
  );
  
  const onSubmitCashIn = async () => {
    if (withdraw && parseFloat(pAmount) < parseFloat(amount)) {
      return notification.error({
        title: 'Lỗi',
        description: 'Số tiền rút cao hơn số tiền hiện có',
        placement: 'bottomRight',
      });
    }

    setCashLoading(true);
    try {
      await BaseAPI.post(withdraw ? 'user/credit/sub' : 'user/credit', {
        id: selectedId,
        amount,
        reason,
      });

      const findNumber = list.find((it) => it._id === selectedId);

      notification.success({
        description: `Đã nạp: ${new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(amount)} cho SĐT ${findNumber.phoneNumber}`,
        placement: 'bottomRight',
      });

      fetchUsers();
      setIsModal(false);
      setAmount(null);
    } catch (e) {
      notification.error({
        description: 'Xảy ra lỗi, vui lòng thử lại!',
        placement: 'bottomRight',
      });
    }

    setCashLoading(false);
  };

  useEffect(() => {
    if (!user.token) {
      history.push('/');
    }
    fetchUsers();
  }, []);

  const columns = [
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số dư',
      dataIndex: 'balance',
      key: 'balance',
      render: (cell) =>
        new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(cell),
    },
    {
      title: 'Mở khoá',
      dataIndex: 'id',
      align: 'right',
      render: (id, row) => (
        <>
          <Button type='danger' onClick={onLockUser(id, row)}>
            Mở khoá tài khoản
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className='keyword-row'>
        <h1>Danh sách tài khoản bị khoá</h1>
        <div className='search'>
          <Input.Search placeholder='Nhập SĐT Hoặc tên' onChange={onChangeKeyword} />
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={[...computedList]}
        pagination={{ pageSize: 10 }}
        columns={columns}
        locale={{
          emptyText: <Empty description='Chưa có dữ liệu hoặc không tìm thấy' />,
        }}
      />

      <Modal
        confirmLoading={cashLoading}
        title={
          withdraw ? `Rút tiền tài khoản ${pNumber}` : `Nạp tiền cho số điện thoại: ${pNumber}`
        }
        visible={isModal}
        onCancel={() => setIsModal(false)}
        onOk={onSubmitCashIn}>
        <div>
          <InputNumber
            style={{ width: '100%' }}
            disabled={cashLoading}
            value={amount}
            formatter={(value) => new Intl.NumberFormat().format(value)}
            parser={(value) => value.split(',').join('')}
            onChange={(value) => setAmount(value)}
            placeholder='Nhập số tiền'
          />
        </div>
        {withdraw && (
          <div style={{ marginTop: 24 }}>
            <Input.TextArea
              disabled={cashLoading}
              onChange={(e) => setReason(e.target ? e.target.value : '')}
              placeholder='Nhập thông tin rút'
            />
          </div>
        )}
      </Modal>

      {/* <Modal
        title={`Lịch sử nạp/ rút tài khoản: ${pNumber}`}
              visible={isHistory}
              onCancel={() => setIsHistory(false)}
              onOk={()=> setIsHistory(false)}
          >

      </Modal> */}
    </div>
  );
};

export default UserBlockedScreen;
