import React, { useState, useMemo, useEffect, createRef } from "react";
import { Form, Input, Button, Radio, notification, Checkbox } from "antd";
import ReactTags from "react-tag-autocomplete";
import BaseAPI from "../../utils/BaseAPI";
import "./index.scss";

import { get } from "lodash";

function SendNotificationScreen() {
  const [isAll,setIsAll] = useState(false)
  const [form] = Form.useForm();

  const [listTags, setListTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const fetchUsers = async () => {
    const params = {
      limit: 100000,
    };
    try {
      const response = await BaseAPI.get("user/all", { params });
      const { data, status } = response;
      if (!response.status) return;
      const arrTemp = get(data, "docs", []).map((item, index) => ({
        id: get(item, "id"),
        name: get(item, "phoneNumber"),
      }));
      setSuggestions(arrTemp);
    } catch (error) {
      notification.warning({
        title: "Thông báo",
        description: error,
        placement: "bottomRight",
      });
    }
  };

  const onFinish = async (formValues) => {
    try {
      if ((listTags.length || isAll) && formValues.content) {
        await BaseAPI.post("/notification/send", {
          isAll,
          phoneNumbers: listTags.slice().map((it) => it.name),
          title: `Thông báo`,
          message: formValues.content,
          type: "ANNOUNCEMENT",
        });
        setListTags([]);
        form.resetFields();
        return notification.success({
          title: "Thông báo",
          description: "Gửi thông báo thành công",
          placement: "bottomRight",
        });
      }
      notification.error({
        title: "Thông báo",
        description: "Gửi thông báo thất bại",
        placement: "bottomRight",
      });
    } catch (error) {
      notification.warning({
        title: "Thông báo",
        description: error,
        placement: "bottomRight",
      });
    }
  };

  const onFinishFailed = () => {
    return notification.error({
      title: "Thông báo",
      description: "Gửi thông báo thất bại",
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const onDelete = (i) => {
    const deleteTag = listTags.slice(0);
    deleteTag.splice(i, 1);
    setListTags(deleteTag);
  };

  const onAddition = (tag) => {
    const filterTag = listTags.filter((item) => item.name === tag.name);
    if (filterTag.length) {
      notification.warning({
        title: "Thông báo",
        description: "Số điện thoại tồn tại",
        placement: "bottomRight",
      });
      return;
    }
    const addTag = [].concat(listTags, tag);
    setListTags(addTag);
  };

  const onValidate = (tag) => {
    if (tag.name.length < 10) {
      notification.warning({
        title: "Thông báo",
        description: "Số điện thoại không hợp lệ",
        placement: "bottomRight",
      });
    }
    return tag.name.length >= 10;
  };

  return (
    <>
      <div className="notice">
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <div className="notice__info">
            <Form.Item className="notice__info-item">
              <Checkbox checked={isAll} onClick={()=> setIsAll(!isAll)}>Gửi toàn bộ</Checkbox>
              {!isAll && (
                <ReactTags
                tags={listTags}
                suggestions={suggestions}
                onDelete={onDelete}
                onAddition={onAddition}
                delimiters={["Enter", "Tab"]}
                placeholderText="Nhập số điện thoại"
                onValidate={onValidate}
              />
              )}
            </Form.Item>
          </div>
          <Form.Item
            name="content"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập thông báo ",
              },
            ]}
          >
            <Input.TextArea
              rows={15}
              placeholder="Nhập thông báo"
            ></Input.TextArea>
          </Form.Item>

          <Form.Item>
            <Button size="large" type="primary" htmlType="submit">
              Gửi thông báo
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default SendNotificationScreen;
